import styled from 'styled-components';



export const Button = styled.div`
   position: fixed; 
   width: 100%;
   left: 95%;
   bottom: 80px;
   height: 20px;
   font-size: 3rem;
   z-index: 99;
   cursor: pointer;
   color:#3C9FE1;
   `

   export const ButtonEmail = styled.div`
   position: fixed; 
   width: 100%;
   left: 95%;
   bottom: 80px;
   height: 20px;
   font-size: 3rem;
   z-index: 99;
   cursor: pointer;
   color:#3C9FE1;
   `

