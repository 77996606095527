
import { useState, useEffect } from 'react';
import Logo from '../components/Logo';
import FormRow from '../components/FormRow';
import Wrapper from '../assets/wrappers/RegisterPage';
import Alert from '../components/Alert';
import { useAppContext } from '../context/appContext';
import { useNavigate } from 'react-router-dom';

const initialState = {
    name: '',
    email: '',
    password: '',
    isMember: true,
};


const Register = () => {

    const navigate = useNavigate(); // Local values
    const [values, setValues] = useState(initialState); // Local values
    const { user, isLoading, showAlert, displayAlert, setupUser } = useAppContext(); // useApp contain global values


    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { name, email, password, isMember } = values;
        if (!email || !password || (!isMember && !name)) {
            displayAlert();
            return;
        }
        const currentUser = {name, email, password}
        if(isMember) {
            // console.log('Already a member!');
            setupUser({
                currentUser, 
                endPoint:'login', 
                alertText: 'Login succesful! Redirecting...'
            }) // set as an object
        }
        else {
            setupUser({
                currentUser, 
                endPoint:'register', 
                alertText: 'User created! Redirecting...'
            }) 
        }
    };
    useEffect(() => {
        if(user) {
            setTimeout(() => {
                navigate('/')
            }, 3000)
        }
    }, [user, navigate]); // iterate it - inwoke it when user making navigate changes


    return (
        <Wrapper className='full-page'>
            <form className='form' onSubmit={onSubmit}>

                <Logo />
                <h3>{values.isMember ? 'Login' : 'Register'}</h3>
                {showAlert && <Alert />}

                {/* name field */}
                <div className="formrow-email" style={{marginBottom:'10px'}}>
                    {!values.isMember && (
                        <FormRow
                            placeholder='Your name'
                            type='text'
                            name='name'
                            value={values.name}
                            handleChange={handleChange}
                        />
                    )}
                </div>
                {/* email input */}
                <div className="formrow-email" style={{marginBottom:'10px'}}>
                    <FormRow
                    placeholder='Your email'
                    type='email'
                    name='email'
                    value={values.email}
                    handleChange={handleChange}
                    />
                </div>
                {/* password input */}
                <div className="formrow-email" style={{marginBottom:'10px'}}>
                <FormRow
                placeholder='Your password'
                    type='password'
                    name='password'
                    value={values.password}
                    handleChange={handleChange}
                />
                </div>
                <button type='submit' className='btn btn-block' disabled={isLoading}>
                    Submit
                </button>
                
            </form>
        </Wrapper>
    );
};

export default Register;