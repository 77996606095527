
import React from 'react';
import Wrapper from '../../assets/wrappers/DashboardFormPage';
import SubsContainer from '../../components/SubsContainer';
import { SearchContainer } from '../../components';



const SubAdmin = () => {

    return (
        <Wrapper>
            <SearchContainer />
            <SubsContainer />
        </Wrapper>

    );
};

export default SubAdmin;