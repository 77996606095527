
import { useEffect, useState } from 'react';
import { useAppContext } from '../context/appContext';
import Loading from './Loading';
import Alert from './Alert';
import Wrapper from '../assets/wrappers/JobsContainer';
import PageBtnContainer from './PageBtnContainer';
import Hero from './Hero';
import axios from 'axios';


const HerosContainer = (props) => {
    const { getHeros, isLoading, page, search, searchStatus, searchType, sort, showAlert } = useAppContext();

    const [heross, setHeross] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [totalHeross, setTotalHeross] = useState(0);
    const [numOfPagess, setNumOfPagess] = useState(0);

    useEffect(() => {
        getHeros(props.isHeroPage);
        getHerosApi(props.isHeroPage);
        // eslint-disable-next-line
    }, [page, search, searchStatus, searchType, sort, props.isHeroPage]);
    

    async function getHerosApi(isHeroPage) {
        
        let urlhero = '';
        if (isHeroPage) {
            // API frontend
            urlhero = `/hero?isLandingHero=${isHeroPage}`; 

        }
        else {
            // API backend
            urlhero = `/hero-admin?isLandingHero=${isHeroPage}`; 

        }
        try {
            const authFetch = axios.create({
                baseURL: 'api/v1',
            });
            // Response interceptor
            authFetch.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    console.log(error.response);
                    if (error.response.status === 401) {
                    }
                    return Promise.reject(error);
                }
            );

            const { data } = await authFetch.get(urlhero);
            const { heros, totalHeros, numOfPages } = data;

            setHeross(heros);
            setTotalHeross(totalHeros);
            setNumOfPagess(numOfPages);
        } catch (error) {
            console.log(error.message);
        }
    }


    if (isLoading) {
        return <Loading center />;
    }

    if (!heross || heross.length === 0) {
        return (
            <Wrapper>
                <h2>Loading...</h2>
            </Wrapper>
        );
    }

    return (
        <>
            {showAlert && <Alert />}
            {/*
            <h5>
                We have {totalFaqss} {faqss.length > 1 && 'FAQs'} 
            </h5>
             */}

            <div className='heros'>
                {heross.map((hero) => {
                    return <Hero key={hero._id} {...hero} isHeroPage={props.isHeroPage} />;
                })}
            </div>
            {numOfPagess > 1 && <PageBtnContainer />}
        </>
    );
};

export default HerosContainer;