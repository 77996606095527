
import React from 'react';
import '../components/Footer/footer.css';


const ReferenceList = () => {

    return (
        <>
            <div className='text-center pt-4 pb-2 mt-4 mb-2'>
                <h2>Our reference list contains:</h2>
                <hr className='pt-2 mt-2' style={{width: '800px', margin: 'auto', fontFamily:'Montserrat'}}></hr>
                <h4 className='text-secondary' style={{fontFamily: 'Montserrat'}}>People who has worked on our site.</h4>
                <h4 className='text-secondary' style={{fontFamily: 'Montserrat'}}>Inspiration from different sources.</h4>
            </div>

            <div className='reference-list-body'>
                <ul>
                    <li className="card" style={{color:'#ececec', backgroundColor:'#842C2A', fontFamily:'Montserrat'}}>
                        <div className="icon"><i className="fa-solid fa-house"></i></div>
                        <div className="title">Jannick Pedersen - Fullstack developer</div>
                        <div className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec iaculis pretium euismod. Aenean elit sapien, varius quis ante sit amet, tincidunt finibus orci. Ut consectetur imperdiet quam, at ultrices mi rutrum ut. Sed semper justo quis nisl ornare interdum in ut ante. </div>
                    </li>
                    <li className="card" style={{color:'#ececec', backgroundColor:'#032437'}}>
                        <div className="icon"><i className="fa-solid fa-gear"></i></div>
                        <div className="title">Rukshan Pokharel - Fullstack developer</div>
                        <div className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec iaculis pretium euismod. Aenean elit sapien, varius quis ante sit amet, tincidunt finibus orci. Ut consectetur imperdiet quam, at ultrices mi rutrum ut. Sed semper justo quis nisl ornare interdum in ut ante. </div>
                    </li>
                    <li className="card" style={{color:'#ececec', backgroundColor:'#842C2A', fontFamily:'Montserrat'}}>
                        <div className="icon"><i className="fa-solid fa-magnifying-glass"></i></div>
                        <div className="title">Youtube channels</div>
                        <div className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec iaculis pretium euismod. Aenean elit sapien, varius quis ante sit amet, tincidunt finibus orci. Ut consectetur imperdiet quam, at ultrices mi rutrum ut. Sed semper justo quis nisl ornare interdum in ut ante. </div>
                    </li>
                    <li className="card" style={{color:'#ececec', backgroundColor:'#032437', fontFamily:'Montserrat'}}>
                        <div className="icon"><i className="fa-solid fa-chart-column"></i></div>
                        <div className="title">Codepen channels</div>
                        <div className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec iaculis pretium euismod. Aenean elit sapien, varius quis ante sit amet, tincidunt finibus orci. Ut consectetur imperdiet quam, at ultrices mi rutrum ut. Sed semper justo quis nisl ornare interdum in ut ante. </div>
                    </li>
                </ul>
            </div>
        </>
    );
};


export default ReferenceList;

