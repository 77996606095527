
import axios from 'axios';
import React, { useState } from 'react';
import { Container } from "react-bootstrap";
import "../../styles/Email.css";
import Form from 'react-bootstrap/Form';


const EmailRestAPI = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Your EmailJS service ID, template ID, and Public Key
        const serviceId = 'service_aqraw2m';
        const templateId = 'template_u390pxq';
        const publicKey = 'nlxmF2a05DbXTjwUQ';

        // Create an object with EmailJS service ID, template ID, Public Key, and Template params
        const data = {
        service_id: serviceId,
        template_id: templateId,
        user_id: publicKey,
        template_params: {
            from_name: name,
            from_email: email,
            to_name: 'NanoScaleSimulations',
            message: message,
        }
        };

        // Send the email using EmailJS
        try {
        const res = await axios.post("https://api.emailjs.com/api/v1.0/email/send", data);
        console.log(res.data);
        setName('');
        setEmail('');
        setMessage('');
        } catch (error) {
        console.error(error);
        }
    }

    return (
        <div>
            <Container fluid>
                <Form onSubmit={handleSubmit} className=''>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label style={{margin:'0px', padding:'0px', fontFamily:'Roboto', fontWeight:'bold'}}>Enter you name</Form.Label>
                        <Form.Control type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </Form.Group>
                
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label style={{margin:'0px', padding:'0px', fontFamily:'Roboto', fontWeight:'bold'}}>Enter your e-mail</Form.Label>
                        <Form.Control type="email" placeholder="example@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label style={{margin:'0px', padding:'0px', fontFamily:'Roboto', fontWeight:'bold'}}>Enter a message</Form.Label>
                        <Form.Control as="textarea" rows={3} value={message} placeholder='Message' onChange={(e) => setMessage(e.target.value)} />
                    </Form.Group>
                
                    <button className='btn btn-block submit-btn' type="submit">Send Email</button>

                </Form>
            </Container>
        </div>
    );

};


export default EmailRestAPI;