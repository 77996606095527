
import { useAppContext } from '../context/appContext';
import { useEffect, useState } from 'react';
import Loading from './Loading';
import Alert from './Alert';
import Wrapper from '../assets/wrappers/BlogsContainer';
import PageBtnContainer from './PageBtnContainer';
import Blog from './Blog';
import axios from 'axios';



const BlogsContainer = (props) => {

     // totalBlogs, numOfPages can be insert for later use
    const { getBlogs, isLoading, page, search, searchStatus, searchType, sort, showAlert } = useAppContext();

    const [blogss, setBlogss] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [totalBlogss, setTotalBlogss] = useState(0);
    const [numOfPagess, setNumOfPagess] = useState(0);

    useEffect(() => {
        getBlogs(props.isBlogPage);
        getBlogsApi(props.isBlogPage);
        // eslint-disable-next-line
    }, [page, search, searchStatus, searchType, sort, props.isBlogPage]);
    


    async function getBlogsApi(isBlogPage) {
    
        let url = '';
        if (isBlogPage) {
            // Frontend API
            url = `/landing-blog?isLanding=${isBlogPage}`;
        }
        else {
            // Backend API
            url = `/blog?isLanding=${isBlogPage}`;
        }
        try {
            const authFetch = axios.create({
                baseURL: 'api/v1',
            });
            // Response interceptor
            authFetch.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    console.log(error.response);
                    if (error.response.status === 401) {
                    }
                    return Promise.reject(error);
                }
            );

            const { data } = await authFetch.get(url);
            const { blogs, totalBlogs, numOfPages } = data;

            setBlogss(blogs);
            setTotalBlogss(totalBlogs);
            setNumOfPagess(numOfPages);
        } catch (error) {
            console.log(error.message);
        }
    }


    if (isLoading) {
        return <Loading center />;
    }

    if (!blogss || blogss.length === 0) {
        return (
            <Wrapper>
                <h2>No blogs to display...</h2>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {showAlert && <Alert />}
            <hr></hr>
            <div className='jobs'>
                {blogss.map((blog) => {
                    return <Blog key={blog._id} {...blog} isBlogPage={props.isBlogPage} />;
                })}
            </div>
            {numOfPagess > 1 && <PageBtnContainer />}
        </Wrapper>
    );
};

export default BlogsContainer;