
import { useAppContext } from '../context/appContext';
import StatItem from './StatItem';
import { FaSuitcaseRolling, FaCalendarCheck, FaBug, } from 'react-icons/fa';
import Wrapper from '../assets/wrappers/StatsContainer';



const StatsContainer = () => {

    const { stats } = useAppContext();

    const defaultStats = [
        {
            title: 'subscribed Emails',
            count: stats.subscribe || 0,
            icon: <FaSuitcaseRolling />,
            color: '#228B22',
            bcg: '#fcefc7',
        },
        {
            title: 'unsubscribed Emails',
            count: stats.unsubscribe || 0,
            icon: <FaBug />,
            color: '#d66a6a',
            bcg: '#e0e8f9',
        }, 
        {
            title: 'Total subscribed Emails',
            count: stats.subscribe + stats.unsubscribe || 0,
            icon: <FaCalendarCheck />,
            color: '#647acb',
            bcg: '#ffeeee',
        },
    ]; 

    return (
        <Wrapper>
            {defaultStats.map((item, index) => {
                return <StatItem key={index} {...item} />;
            })}
        </Wrapper>
    );
};


export default StatsContainer;