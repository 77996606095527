
import { useEffect, useState } from 'react';
import { useAppContext } from '../context/appContext';
import Loading from './Loading';
import Alert from './Alert';
import PageBtnContainer from './PageBtnContainer';
import Email from './Email';
import axios from 'axios';
import { Container } from 'react-bootstrap';


const EmailsContainer = (props) => {
    const { getEmails, totalEmails, isLoading, page, searchEmail, searchStatusEmail, sortEmail, showAlert } = useAppContext();

    const [emailss, setEmailss] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [totalEmailss, setTotalEmailss] = useState(0);
    const [numOfPagess, setNumOfPagess] = useState(0);

    useEffect(() => {
        getEmails(props.isEmailPage);
        getEmailsApi(props.isEmailPage);
        // eslint-disable-next-line
    }, [page, searchEmail, searchStatusEmail, sortEmail, props.isEmailPage]);
    

    async function getEmailsApi(isEmailPage) {
        
        let urlemail = '';
        if (isEmailPage) {
            // API frontend
            urlemail = `/email?isLandingEmail=${isEmailPage}`; 

        }
        else {
            // API backend
            urlemail = `/email-admin?isLandingEmail=${isEmailPage}page=${page}&statusEmail=${searchStatusEmail}&sortEmail=${sortEmail}`; 

        }
        try {
            const authFetch = axios.create({
                baseURL: 'api/v1',
            });
            // Response interceptor
            authFetch.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    console.log(error.response);
                    if (error.response.status === 401) {
                    }
                    return Promise.reject(error);
                }
            );

            const { data } = await authFetch.get(urlemail);
            const { emails, totalEmails, numOfPages } = data;

            setEmailss(emails);
            setTotalEmailss(totalEmails);
            setNumOfPagess(numOfPages);
        } catch (error) {
            console.log(error.message);
        }
    }


    if (isLoading) {
        return <Loading center />;
    }

    if (!emailss || emailss.length === 0) {
        return (
            <Container>
                <h2>No emails to display...</h2>
            </Container>
        );
    }

    return (
        <Container>
            {showAlert && <Alert />}
            <h5 className='my-4'>
            <span style={{color:'red'}}>{totalEmails}</span> Emails with status: {emailss.length > 0 } "<span style={{color:'red'}}>{searchStatusEmail}</span>"
            </h5>
            <div className='emails'>
                {emailss.map((email) => {
                    return <Email key={email._id} {...email} isPage={props.isEmailPage} />;
                })}
            </div>
            {numOfPagess > 1 && <PageBtnContainer />}
        </Container>
    );
};

export default EmailsContainer;