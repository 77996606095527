
import React, { useState } from 'react';
import "../../styles/Email.css";
// import { FaBusinessTime, FaLongArrowAltRight } from "react-icons/fa";
import Collapse from 'react-bootstrap/Collapse';
import { useAppContext } from '../../context/appContext';
import { FormRow, Alert } from '../../components';
import { Button, Container, Card, Row, Col } from "react-bootstrap";
import TextAreaRow from "../TextAreaRow";
import { AnalyticEvent } from '../GA4/AnalyticEvent.js';
import EmailRestAPI from './EmailRestAPI';
// import EmailForm from './EmailForm';


const Email = () => {

    const [openTwo] = useState(false);

    // const [openTwo, setOpenTwo] = useState(false); use the above when not using setOpenTwo

    const { createEmail, showAlert, displayAlert, emailname, emailcomponent, emailtext, handleChange, clearEmailValues, } = useAppContext();

    const handleSubmit = (e) => {
        e.preventDefault();
    
        if (!emailname || !emailcomponent || !emailtext) {
            displayAlert()
            AnalyticEvent("Email failed")
            return
        }
        AnalyticEvent("Email Success")
        createEmail();
    }

    const handleEmailInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        handleChange({ name, value })
    }


    return (
        <div className="email-body">
            <div className="body">
                <div className="atom">
                    <div className="line line-1"></div>
                    <div className="line line-2"></div>
                    <div className="line line-3"></div>
                </div>
            </div>
            <Container fluid className="mt-2 pt-2">
                <div className='' style={{marginTop:'20px'}}>
                    <h4 className="text-center" style={{fontSize:'24px', fontFamily:'Roboto', fontWeight:'Bold'}}>Message us</h4>
                    <h6 className="text-center text-secondary" style={{fontFamily:'Roboto', fontSize:'16'}}>For any kind of questions and inquiries, fill out the form below and we will get back to you as soon as possible.</h6>
                </div>
                <hr/>
                {/* <EmailForm /> */} 
                <EmailRestAPI />
                <Row style={{marginTop:'20px'}}>
                    <Col>
                        {/* <div className='px-2'>
                            <FaBusinessTime  
                                onClick={() => setOpenTwo(!openTwo)}
                                aria-controls="example-collapse-text"
                                aria-expanded={openTwo}
                                size={40}
                                color='#F28C28'
                                className='icon-shake'
                                style={{cursor: 'pointer'}}
                            />
                            <h5 className=''>Technicalities 
                                <FaLongArrowAltRight 
                                    size={30} 
                                    className='icon-shake mx-1' 
                                    color='#F28C28'
                                    onClick={() => setOpenTwo(!openTwo)}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={openTwo} 
                                    style={{cursor: 'pointer'}}
                                />
                            </h5>
                        </div>   */}
                        <div style={{minHeight: '50px' }}>
                            <Collapse in={openTwo} dimension="width">
                                <div id="example-collapse-text">
                                    <Card body style={{}}>
                                        {/* <EmailForm /> */} 
                                        <React.Fragment>
                                            <form className=''>
                                                {showAlert && <Alert />}
                                                <div className='form-center'>
                                                
                                                    {/* Name*/}
                                                    <div className="formrow-name" style={{marginBottom:'10px'}}>
                                                        <p style={{margin:'0px', padding:'0px', fontFamily:'Roboto', fontWeight:'bold', color:'#3C9FE1'}}>Full name:</p>
                                                        <FormRow
                                                            placeholder='John Doe'
                                                            type='text'
                                                            name='emailname'
                                                            value={emailname}
                                                            handleChange={handleEmailInput}
                                                            
                                                        />
                                                    </div>
                                                    {/* email adresse */}
                                                    <div className="formrow-email" style={{marginBottom:'10px'}}>
                                                        <p style={{margin:'0px', padding:'0px', fontFamily:'Roboto', fontWeight:'bold', color:'#3C9FE1'}}>Email:</p>
                                                        <FormRow
                                                            placeholder='johndoe@gmail.com'
                                                            type='text'
                                                            name='emailcomponent'
                                                            value={emailcomponent}
                                                            handleChange={handleEmailInput}
                                                        />
                                                    </div>
                                                    {/* text */}
                                                    <div className="formrow-text py-2 my-2" style={{marginBottom:'20px'}}>
                                                        <p style={{margin:'0px', padding:'0px', fontFamily:'Roboto', fontWeight:'bold', color:'#3C9FE1'}}>Your message:</p>
                                                        <TextAreaRow
                                                            placeholder='Your text here'
                                                            type='text'
                                                            name='emailtext'
                                                            value={emailtext}
                                                            handleChange={handleEmailInput}
                                                        />
                                                    </div>
                                                    {/* btn container, disabled={isLoading} */}
                                                    <div className='btn-container' style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                        <Button type='submit' className='btn btn-block submit-btn' onClick={handleSubmit}  >
                                                            submit
                                                        </Button>
                                                        <Button className='btn btn-block clear-btn mx-4 px-4 text-dark' onClick={(e) => {
                                                            e.preventDefault()
                                                            clearEmailValues()
                                                        }}
                                                        >
                                                            clear
                                                        </Button>
                                                    </div>
                                                </div>
                                            </form>
                                        </React.Fragment>
                                    </Card>
                                </div>
                            </Collapse>
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </div>
    );
};

export default Email;

