import contactImage from "../img/nanoContact.png";
import contactImage2 from "../img/nanoContact2.png";
import contactImage3 from "../img/nanoContact3.png";
import { RiMailSendLine } from "react-icons/ri";

export const contactinfo = [
    {
        title: 'Contact Information',
        mailtitle: 'Mail',
        mail: 'info@nanoscalesimulations.com',
        phonetitle: 'Phone',
        phone: '+45 29 36 10 09',
    }
];

export const contactinfoimg = [contactImage, contactImage2, contactImage3];

export const contactus = [
    {
        contacticon: <RiMailSendLine/>,
        contacttitle: 'Contact Us',
        contacttext: 'We will reply fast as possible',
    }
];