import React from 'react';
import { PostHogProvider} from 'posthog-js/react'
import posthog from 'posthog-js';
import ReactDOM from 'react-dom/client';
import 'normalize.css'; 
import './index.css';
import App from './App';
import { AppProvider } from './context/appContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";


posthog.init( 
  'phc_o4dGm7hp26QVar6h1Tgy21ZAn2LI0DZPOGpNjmW3UIa', { api_host: 'https://app.posthog.com' },
) 

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <PostHogProvider 
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        <AppProvider>
          <App />
        </AppProvider>
      </PostHogProvider>
  </React.StrictMode>
);

