

const FormRowSelect = ({ placeholder, name, value, handleChange, list }) => {

    return (
        <div className='form-row'>
    
            <select
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={handleChange}
            className='form-select'
            >
            {list.map((itemValue, index) => {
                return (
                <option key={index} value={itemValue}>
                    {itemValue}
                </option>
                );
            })}
            </select>
        </div>
    );
};


export default FormRowSelect;