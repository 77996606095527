
import React, { useState } from 'react';
import '../styles/Blog.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useAppContext } from '../context/appContext';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { red } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import he from 'he';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';




const Blog = ({ _id, title, subtitle, author, description, createdAt, fulltext, fulltexttwo, tagone, tagtwo, tagthree, tagfour, blogImage, isBlogPage }) => {


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [fullscreen, setFullscreen] = useState(true);
    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
    }


    const { setEditBlog, deleteBlog } = useAppContext()

    let date = moment(createdAt)
    date = date.format('MMM Do, YYYY');

    // Get the index of the last occurrence of 'blogImages/' in the URL
    const lastIndex = blogImage?.lastIndexOf('blogImages/');

    // Extract the image name from the URL using substring
    const blogImageName = blogImage?.substring(lastIndex + 11);

    

    return (
        <div style={{ paddingBottom: 30 }}>
            <Container fluid>

                {/* BLOG CARD STARTS */}
                <Card onClick={handleShow} >
                    <Row>
                        <Col>
                        <CardMedia component="img" style={{ maxHeight:'300px', cursor: 'pointer' }} src={"../../blogImages/" + blogImageName} alt="Blog img" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                {author.charAt(0)}
                            </Avatar>
                        }
                        title={
                            <div style={{ color: '#3C9FE1' }}>
                                <div className='blog-card-titel'>
                                    <h5>{title}</h5>
                                    <h6>{subtitle}</h6>
                                </div>
                            </div>
                        }
                        
                    />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CardContent>
                                <div className='text-secondary' style={{ fontSize: 16 }}>
                                    <div className='blog-p'>
                                        {description}
                                    </div>
                                </div>
                                <ButtonToolbar className='mt-2' aria-label="Toolbar with button groups" style={{borderRadius: '30px'}}>
                                    <ButtonGroup className="me-2 py-2" aria-label="First group">
                                        <Button variant='primary' style={{fontSize:'14px'}}>{tagone}</Button> 
                                    </ButtonGroup>
                                    <ButtonGroup className="me-2 py-2" aria-label="Second group">
                                        <Button variant='secondary' style={{fontSize:'14px'}}>{tagtwo}</Button>
                                    </ButtonGroup> 
                                    <ButtonGroup className="me-2 py-2" aria-label="Third group">
                                        <Button variant='primary' style={{fontSize:'14px'}}>{tagthree}</Button>
                                    </ButtonGroup>
                                    <ButtonGroup className="me-2 py-2" aria-label="Forth group">
                                        <Button variant='secondary' style={{fontSize:'14px'}}>{tagfour}</Button>
                                    </ButtonGroup>
                                </ButtonToolbar>
                                <hr></hr>
                                <div style={{ fontSize: 14, color: '#3C9FE1' }}>
                                    <div className='blog-dato'>
                                        <p>{date}</p>
                                        <p>{author}</p>
                                    </div>
                                </div>
                            </CardContent>
                        </Col>
                    </Row>
                
                </Card>
                {/* BLOG CARD STARTS */}

                {/* MODAL STARTS */}
                <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
                    <Modal.Header closeButton />
                    <Modal.Body className="show-grid">
                        
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <Image src={"../../blogImages/" + blogImageName} alt="Blog image" />
                                <CardHeader
                                    title={
                                        <div>
                                            <h5 className='px-4' style={{color:'#3C9FE1'}}>{author}</h5>
                                            <h5 className='px-4 text-secondary'>{date}</h5>
                                        </div>            
                                        }
                                />    
                                <ButtonToolbar className='mt-2' aria-label="Toolbar with button groups" style={{borderRadius: '30px'}}>
                                    <ButtonGroup className="me-2 py-2" aria-label="First group">
                                        <Button variant='primary' style={{fontSize:'14px'}}>{tagone}</Button> 
                                    </ButtonGroup>
                                    <ButtonGroup className="me-2 py-2" aria-label="Second group">
                                        <Button variant='secondary' style={{fontSize:'14px'}}>{tagtwo}</Button>
                                    </ButtonGroup> 
                                    <ButtonGroup className="me-2 py-2" aria-label="Third group">
                                        <Button variant='primary' style={{fontSize:'14px'}}>{tagthree}</Button>
                                    </ButtonGroup>
                                    <ButtonGroup className="me-2 py-2" aria-label="Forth group">
                                        <Button variant='secondary' style={{fontSize:'14px'}}>{tagfour}</Button>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="recipe" sx={{ bgcolor: red[500] }}>
                                            {author.charAt(0)}
                                        </Avatar>
                                    }
                                    title={
                                        <div>
                                            <h3 style={{color:'#3C9FE1', fontWeight:'bold'}}>{title}</h3>
                                            <h4>{subtitle}</h4>
                                        </div>
                                    }
                                />    
                                <hr></hr>
                            
                                <div style={{maxWidth:'900px'}}>
                                    <div className='px-4' dangerouslySetInnerHTML={{ __html: he.decode(fulltext) }}></div><br/><br/>
                                    <div className='px-4' dangerouslySetInnerHTML={{ __html: he.decode(fulltexttwo) }}></div>
                                </div>
                            </Col>

                        </Row>
                    </Modal.Body>
                </Modal>
                {/* MODAL ENDS */}

                {isBlogPage ? (<>
                </>) : <>
                    <footer>
                        <div className='actions'>
                            <Link
                                to='/blogs'
                                className='btn edit-btn btn-success'
                                onClick={() => setEditBlog(_id)}
                            >
                                Edit
                            </Link>
                            <button
                                type='button'
                                className='btn delete-btn btn-danger mx-2'
                                onClick={() => deleteBlog(_id)}
                            >
                                Delete
                            </button>
                        </div>
                    </footer>
                </>}
            </Container>
        </div>

    )
}

export default Blog