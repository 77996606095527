
// Various
export const DISPLAY_ALERT = 'SHOW_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const HANDLE_CHANGE = 'HANDLE_CHANGE';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const HANDLE_FILE_CHANGE = 'HANDLE_FILE_CHANGE';

// For Stats
export const SHOW_STATS_BEGIN = 'SHOW_STATS_BEGIN';
export const SHOW_STATS_SUCCESS = 'SHOW_STATS_SUCCESS';

export const SHOW_STATSEMAIL_BEGIN = 'SHOW_STATSEMAIL_BEGIN';
export const SHOW_STATSEMAIL_SUCCESS = 'SHOW_STATSEMAIL_SUCCESS';

// For the User
export const LOGOUT_USER = 'LOGOUT_USER';
export const SETUP_USER_BEGIN = 'SETTUP_USER_BEGIN';
export const SETUP_USER_SUCCESS = 'SETUP_USER_SUCCESS';
export const SETUP_USER_ERROR = 'SETUP_USER_ERROR';
export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const GET_CURRENT_USER_BEGIN = 'GET_CURRENT_USER_BEGIN';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';


// For the Values
export const CLEAR_VALUES = 'CLEAR_VALUES';
export const CLEAR_HERO_VALUES = 'CLEAR_HERO_VALUES';
export const CLEAR_BLOG_VALUES = 'CLEAR_BLOG_VALUES';
export const CLEAR_FAQ_VALUES = 'CLEAR_FAQ_VALUES';
export const CLEAR_WORKER_VALUES = 'CLEAR_WORKER_VALUES';
export const CLEAR_EMAIL_VALUES = 'CLEAR_EMAIL_VALUES';
export const CLEAR_SUB_VALUES = 'CLEAR_SUB_VALUES';


// For home hero
export const GET_HEROS_BEGIN = 'GET_HEROS_BEGIN';
export const GET_CLIENT_HEROS_BEGIN = 'GET_CLIENT_HEROS_BEGIN';
export const GET_HEROS_SUCCESS = 'GET_HEROS_SUCCESS';
export const GET_CLIENT_HEROS_SUCCESS = 'GET_CLIENT_HEROS_SUCCESS';
export const SET_EDIT_HERO = 'SET_EDIT_HERO';
export const DELETE_HERO_BEGIN = 'DELETE_HERO_BEGIN';
export const DELETE_HERO_ERROR = 'DELETE_HERO_ERROR';
export const EDIT_HERO_BEGIN = 'EDIT_HERO_BEGIN';
export const EDIT_HERO_SUCCESS = 'EDIT_HERO_SUCCESS';
export const EDIT_HERO_ERROR = 'EDIT_HERO_ERROR';

// For Blog
export const CREATE_BLOG_BEGIN = 'CREATE_BLOG_BEGIN';
export const CREATE_BLOG_SUCCESS = 'CREATE_BLOG_SUCCESS';
export const CREATE_BLOG_ERROR = 'CREATE_BLOG_ERROR';
export const GET_BLOGS_BEGIN = 'GET_BLOGS_BEGIN';
export const GET_CLIENT_BLOGS_BEGIN = 'GET_CLIENT_BLOGS_BEGIN';
export const GET_BLOGS_SUCCESS = 'GET_BLOGS_SUCCESS';
export const GET_CLIENT_BLOGS_SUCCESS = 'GET_CLIENT_BLOGS_SUCCESS';
export const SET_EDIT_BLOG = 'SET_EDIT_BLOG';
export const DELETE_BLOG_BEGIN = 'DELETE_BLOG_BEGIN';
export const DELETE_BLOG_ERROR = 'DELETE_BLOG_ERROR';
export const EDIT_BLOG_BEGIN = 'EDIT_BLOG_BEGIN';
export const EDIT_BLOG_SUCCESS = 'EDIT_BLOG_SUCCESS';
export const EDIT_BLOG_ERROR = 'EDIT_BLOG_ERROR';

// For FAQ
export const CREATE_FAQ_BEGIN = 'CREATE_FAQ_BEGIN';
export const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS';
export const CREATE_FAQ_ERROR = 'CREATE_FAQ_ERROR';
export const GET_FAQS_BEGIN = 'GET_FAQS_BEGIN';
export const GET_CLIENT_FAQS_BEGIN = 'GET_CLIENT_FAQS_BEGIN';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';
export const GET_CLIENT_FAQS_SUCCESS = 'GET_CLIENT_FAQS_SUCCESS';
export const SET_EDIT_FAQ = 'SET_EDIT_FAQ';
export const DELETE_FAQ_BEGIN = 'DELETE_FAQ_BEGIN';
export const DELETE_FAQ_ERROR = 'DELETE_FAQ_ERROR';
export const EDIT_FAQ_BEGIN = 'EDIT_FAQ_BEGIN';
export const EDIT_FAQ_SUCCESS = 'EDIT_FAQ_SUCCESS';
export const EDIT_FAQ_ERROR = 'EDIT_FAQ_ERROR';

// For Worker
export const CREATE_WORKER_BEGIN = 'CREATE_WORKER_BEGIN';
export const CREATE_WORKER_SUCCESS = 'CREATE_WORKER_SUCCESS';
export const CREATE_WORKER_ERROR = 'CREATE_WORKER_ERROR';
export const GET_WORKERS_BEGIN = 'GET_WORKERS_BEGIN';
export const GET_CLIENT_WORKERS_BEGIN = 'GET_CLIENT_WORKERS_BEGIN';
export const GET_WORKERS_SUCCESS = 'GET_WORKERS_SUCCESS';
export const GET_CLIENT_WORKERS_SUCCESS = 'GET_CLIENT_WORKERS_SUCCESS';
export const SET_EDIT_WORKER = 'SET_EDIT_WORKER';
export const DELETE_WORKER_BEGIN = 'DELETE_WORKER_BEGIN';
export const DELETE_WORKER_ERROR = 'DELETE_WORKER_ERROR';
export const EDIT_WORKER_BEGIN = 'EDIT_WORKER_BEGIN';
export const EDIT_WORKER_SUCCESS = 'EDIT_WORKER_SUCCESS';
export const EDIT_WORKER_ERROR = 'EDIT_WORKER_ERROR';

// For Email
export const CREATE_EMAIL_BEGIN = 'CREATE_EMAIL_BEGIN';
export const CREATE_EMAIL_SUCCESS = 'CREATE_EMAIL_SUCCESS';
export const CREATE_EMAIL_ERROR = 'CREATE_EMAIL_ERROR';
export const GET_EMAILS_BEGIN = 'GET_EMAILS_BEGIN';
export const GET_EMAILS_SUCCESS = 'GET_EMAILS_SUCCESS';
export const GET_CLIENT_EMAILS_BEGIN = 'GET_CLIENT_EMAILS_BEGIN';
export const GET_CLIENT_EMAILS_SUCCESS = 'GET_CLIENT_EMAILS_SUCCESS';
export const DELETE_EMAIL_BEGIN = 'DELETE_EMAIL_BEGIN';
export const DELETE_EMAIL_ERROR = 'DELETE_EMAIL_ERROR';
export const SET_EDIT_EMAIL = 'SET_EDIT_EMAIL';
export const EDIT_EMAIL_BEGIN = 'EDIT_EMAIL_BEGIN';
export const EDIT_EMAIL_SUCCESS = 'EDIT_EMAIL_SUCCESS';
export const EDIT_EMAIL_ERROR = 'EDIT_EMAIL_ERROR';

// For Sub
export const CREATE_SUB_BEGIN = 'CREATE_SUB_BEGIN';
export const CREATE_SUB_SUCCESS = 'CREATE_SUB_SUCCESS';
export const CREATE_SUB_ERROR = 'CREATE_SUB_ERROR';
export const GET_SUBS_BEGIN = 'GET_SUBS_BEGIN';
export const GET_SUBS_SUCCESS = 'GET_SUBS_SUCCESS';
export const GET_CLIENT_SUBS_BEGIN = 'GET_CLIENT_SUBS_BEGIN';
export const GET_CLIENT_SUBS_SUCCESS = 'GET_CLIENT_SUBS_SUCCESS';
export const DELETE_SUB_BEGIN = 'DELETE_SUB_BEGIN';
export const DELETE_SUB_ERROR = 'DELETE_SUB_ERROR';

