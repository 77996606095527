
import { useAppContext } from '../context/appContext';
import StatItem from './StatItem';
import { FaSuitcaseRolling, FaCalendarCheck, FaBug, } from 'react-icons/fa';
import Wrapper from '../assets/wrappers/StatsContainer';



const StatsContainerEmail = () => {

    const { statsEmail } = useAppContext();

    const defaultStatsEmail = [
        {
            title: 'Emails not read',
            count: statsEmail.unreademail || 0,
            icon: <FaBug />,
            color: '#d66a6a',
            bcg: '#e0e8f9',
        }, 
        {
            title: 'Read Emails',
            count: statsEmail.reademail || 0,
            icon: <FaSuitcaseRolling />,
            color: '#228B22',
            bcg: '#fcefc7',
        },
        {
            title: 'Total Emails in inbox',
            count: statsEmail.reademail + statsEmail.unreademail || 0,
            icon: <FaCalendarCheck />,
            color: '#647acb',
            bcg: '#ffeeee',
        },
    ]; 

    return (
        <Wrapper>
            {defaultStatsEmail.map((item, index) => {
                return <StatItem key={index} {...item} />;
            })}
        </Wrapper>
    );
};


export default StatsContainerEmail;