
import React, { useState } from 'react';
import { Container } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsFillBellFill } from "react-icons/bs";
import { useAppContext } from '../../context/appContext';
import { FormRow, Alert, FormRowSelect } from '../../components';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';



function MydModalWithGrid(props) {

    const {isLoading, createSub, showAlert, displayAlert, subscribeEmail, handleChange, status, statusOptions, } = useAppContext();

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!subscribeEmail) {
            displayAlert()
            return
        }
        createSub();
    }

    const handleSubInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        handleChange({ name, value })
    }

    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className="grid-example">
                <Container fluid>
                    <Row>
                        <Col xs={12} md={12} lg={12} xl={12}>
                        <h5 className='text-center'>Cancel subscription</h5>
                        <hr></hr>
                        <p className='text-center my-2 py-2'>Her kan du ændre din subscription status</p>
                        <div className="subscriber-form">
                        {showAlert && <Alert />}
                        <Container fluid style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            {/* Sub status */}
                            <FormRowSelect
                                name='status'
                                value={status}
                                handleChange={handleSubInput}
                                list={statusOptions}
                            />
                        </Container>
                        <InputGroup className="mb-2" id="sub-input" style={{display:'flex', justifyContent:'center', alignItems:'center', fontSize:'20px', padding:'10px'}}>
                            {/* Email*/}
                            <div className="" style={{ maxWidth:'800px'}}>
                                <FormRow
                                    style={{borderTopLeftRadius:30, borderBottomLeftRadius:30}}
                                    className="text-dark"
                                    placeholder='JohnDoe@gmail.com'
                                    type='text'
                                    name='subscribeEmail'
                                    value={subscribeEmail}
                                    handleChange={handleSubInput}
                                />
                            </div>
                            <Button className='home-inputgroup-button' style={{backgroundColor:'orange', borderTopRightRadius:30, borderBottomRightRadius:30, maxHeight:'40px'}} variant="outline-dark" onClick={handleSubmit}  disabled={isLoading}>
                                <BsFillBellFill size={20} style={{marginBottom:4, marginRight:5}} /> 
                                Confirm
                            </Button>
                        </InputGroup>
                    </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}


export default function UnSub() {

    const [modalShow, setModalShow] = useState(false);

    return (
        <Container fluid className="Unsub-body" style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom: '10px', marginTop: '20px'}}>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                Cancel Subscription
            </Button>
            <MydModalWithGrid show={modalShow} onHide={() => setModalShow(false)} />
        </Container>
    );

};