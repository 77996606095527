
import { FormRow, FormRowSelect } from '.';
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/SearchContainer';
import { useState, useMemo } from 'react';


const SearchContainerEmail = () => {

    const [localSearch, setLocalSearch] = useState('');
    // eslint-disable-next-line no-unused-vars
    const { isLoading, handleChange, clearFilters, searchEmail, searchStatusEmail, statusOptionsEmail, sortEmail, sortOptionsEmail,
    } = useAppContext();

    const handleSearch = (e) => {
        handleChange({ name: e.target.name, value: e.target.value });
    };

    const debounce = () => {
        let timeoutID;
        return (e) => {
            setLocalSearch(e.target.value);
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => {
                handleChange({ name: e.target.name, value: e.target.value });
            }, 1000);
        };
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setLocalSearch('');
        clearFilters();
    };

    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const optimizedDebounce = useMemo(() => debounce(), []);
    return (
        <Wrapper>
            <form className='form'>
                <h4>search form</h4>

                {/* search position */}
                <div className='form-center'>
                    <FormRow
                        type='text'
                        name='searchEmail'
                        value={localSearch}
                        handleChange={optimizedDebounce}
                    ></FormRow>

                    {/* search by status */}
                    <FormRowSelect
                        labelText='email status'
                        name='searchStatusEmail'
                        value={searchStatusEmail}
                        handleChange={handleSearch}
                        list={['all', ...statusOptionsEmail]}
                    ></FormRowSelect>

                    {/* sort */}
                    <FormRowSelect
                        name='sortEmail'
                        value={sortEmail}
                        handleChange={handleSearch}
                        list={sortOptionsEmail}
                    ></FormRowSelect>
                    <button
                        className='btn btn-block btn-danger'
                        disabled={isLoading}
                        onClick={handleSubmit}
                    >
                        clear filters
                    </button>
                </div>
            </form>
        </Wrapper>
    );

};


export default SearchContainerEmail;