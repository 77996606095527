
import {
    // GLOBAL
    DISPLAY_ALERT, CLEAR_ALERT, TOGGLE_SIDEBAR, HANDLE_CHANGE, HANDLE_FILE_CHANGE, CLEAR_VALUES, SHOW_STATS_BEGIN, SHOW_STATS_SUCCESS, CLEAR_FILTERS, CHANGE_PAGE,

    // USER
    SETUP_USER_BEGIN, SETUP_USER_SUCCESS, SETUP_USER_ERROR, LOGOUT_USER, UPDATE_USER_BEGIN, UPDATE_USER_SUCCESS, UPDATE_USER_ERROR, GET_CURRENT_USER_BEGIN, GET_CURRENT_USER_SUCCESS, 
    

    // HOME HERO
    GET_HEROS_BEGIN, GET_HEROS_SUCCESS, SET_EDIT_HERO, DELETE_HERO_BEGIN, DELETE_HERO_ERROR, EDIT_HERO_BEGIN, EDIT_HERO_SUCCESS, EDIT_HERO_ERROR, GET_CLIENT_HEROS_BEGIN, GET_CLIENT_HEROS_SUCCESS, CLEAR_HERO_VALUES,

    // BLOG
    CREATE_BLOG_BEGIN, CREATE_BLOG_SUCCESS, CREATE_BLOG_ERROR, GET_BLOGS_BEGIN, GET_BLOGS_SUCCESS, SET_EDIT_BLOG, DELETE_BLOG_BEGIN, DELETE_BLOG_ERROR, EDIT_BLOG_BEGIN, EDIT_BLOG_SUCCESS, EDIT_BLOG_ERROR, GET_CLIENT_BLOGS_BEGIN, GET_CLIENT_BLOGS_SUCCESS, CLEAR_BLOG_VALUES,
    
    // FAQ
    CREATE_FAQ_BEGIN, CREATE_FAQ_SUCCESS, CREATE_FAQ_ERROR, GET_FAQS_BEGIN, GET_FAQS_SUCCESS, SET_EDIT_FAQ, DELETE_FAQ_BEGIN, DELETE_FAQ_ERROR, EDIT_FAQ_BEGIN, EDIT_FAQ_SUCCESS, EDIT_FAQ_ERROR, GET_CLIENT_FAQS_BEGIN, GET_CLIENT_FAQS_SUCCESS, CLEAR_FAQ_VALUES,

     // WORKER
    CREATE_WORKER_BEGIN, CREATE_WORKER_SUCCESS, CREATE_WORKER_ERROR, GET_WORKERS_BEGIN, GET_WORKERS_SUCCESS, SET_EDIT_WORKER, DELETE_WORKER_BEGIN, DELETE_WORKER_ERROR, EDIT_WORKER_BEGIN, EDIT_WORKER_SUCCESS, EDIT_WORKER_ERROR, GET_CLIENT_WORKERS_BEGIN, GET_CLIENT_WORKERS_SUCCESS, CLEAR_WORKER_VALUES,

     // EMAIL
    CREATE_EMAIL_BEGIN, CREATE_EMAIL_SUCCESS, CREATE_EMAIL_ERROR, GET_EMAILS_BEGIN, GET_EMAILS_SUCCESS, GET_CLIENT_EMAILS_BEGIN, GET_CLIENT_EMAILS_SUCCESS, CLEAR_EMAIL_VALUES, DELETE_EMAIL_BEGIN, DELETE_EMAIL_ERROR, SHOW_STATSEMAIL_BEGIN, SHOW_STATSEMAIL_SUCCESS, SET_EDIT_EMAIL, EDIT_EMAIL_BEGIN, EDIT_EMAIL_SUCCESS, EDIT_EMAIL_ERROR,

    // SUB
    CREATE_SUB_BEGIN, CREATE_SUB_SUCCESS, CREATE_SUB_ERROR, GET_SUBS_BEGIN, GET_SUBS_SUCCESS, GET_CLIENT_SUBS_BEGIN, GET_CLIENT_SUBS_SUCCESS, CLEAR_SUB_VALUES, DELETE_SUB_BEGIN, DELETE_SUB_ERROR,

    

} from "./actions";

import { initialState } from "./appContext";


const reducer = (state, action) => {

    if (action.type === DISPLAY_ALERT) {
        return {
            ...state,
            showAlert: true,
            alertType: 'danger',
            alertText: 'Please provide all values!',
        };
    }

    if (action.type === CLEAR_ALERT) {
        return {
            ...state,
            showAlert: false,
            alertType: '',
            alertText: '',
        };
    }

    // Setup user with register and login
    if (action.type === SETUP_USER_BEGIN) {
        return {
            ...state,
            isLoading: true
        };
    }

    if (action.type === SETUP_USER_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            user: action.payload.user,
            userLocation: action.payload.location,
            jobLocation: action.payload.location,
            showAlert: true,
            alertType: 'success',
            alertText: action.payload.alertText,
        };
    }

    if (action.type === SETUP_USER_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }
    //

    if (action.type === TOGGLE_SIDEBAR) {
        return {
            ...state,
            showSidebar: !state.showSidebar,
        };
    }

    if (action.type === LOGOUT_USER) {
        return {
            ...initialState,
            userLoading: false,
        };
    }

    if (action.type === UPDATE_USER_BEGIN) {
        return { ...state, isLoading: true }
    }

    if (action.type === UPDATE_USER_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            user: action.payload.user,
            userLocation: action.payload.location,
            jobLocation: action.payload.location,
            showAlert: true,
            alertType: 'success',
            alertText: 'User Profile Updated!',
        }
    }

    if (action.type === UPDATE_USER_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        }
    }


    // CLEAR FILTERS
    if (action.type === CLEAR_FILTERS) {
        return {
            ...state,
            search: '',
            searchStatus: 'all',
            sort: 'latest',
            searchEmail: '',
            searchStatusEmail: 'all' 
        };
    }
    if (action.type === CHANGE_PAGE) {
        return { ...state, page: action.payload.page };
    }

    if (action.type === HANDLE_CHANGE) {
        // set back to first page
        return {
            ...state,
            page: 1,
            [action.payload.name]: action.payload.value
        };
    }

    if (action.type === HANDLE_FILE_CHANGE) {
        return {
            ...state,
            page: 1,
            [action.payload.name]: action.payload.value,
        };
    }

    // CLEAR VALUES
    if (action.type === CLEAR_VALUES) {
        const initialState = {
            isEditing: false,
            editJobId: '',
            position: '',
            company: '',
            jobLocation: state.userLocation,
            jobType: 'full-time',
            status: 'pending',
            title: '',
            subtitle: '',
            author: '',
            text: '',
            fulltext: '',
            readmore: '',
            fulltexttwo: '',
            tagone: '',
            tagtwo: '',
            tagthree: '',
            tagfour: '',
        };
        return { ...state, ...initialState };
    }



    if (action.type === SHOW_STATS_BEGIN) {
        return { ...state, isLoading: true, showAlert: false };
    }

    if (action.type === SHOW_STATS_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            stats: action.payload.stats,
            monthlyApplications: action.payload.monthlyApplications,
        };
    }



    // CHANGE PAGE
    if (action.type === CHANGE_PAGE) {
        return { ...state, page: action.payload.page };
    }


    // GET CURRENT USER COOKIE
    if (action.type === GET_CURRENT_USER_BEGIN) {
        return { ...state, userLoading: true, showAlert: false };
    }

    if (action.type === GET_CURRENT_USER_SUCCESS) {
        return {
            ...state,
            userLoading: false,
            user: action.payload.user,
            userLocation: action.payload.location,
            jobLocation: action.payload.location,
        };
    }



    // HOME HERO START
    if (action.type === CLEAR_HERO_VALUES) {
        const initialState = {
            isEditing: false,
            editHeroId: '',
            herotitle: '',
            herotext: '',
            herolink: '',
        };
        return {
            ...state,
            ...initialState,
        };
    }


    if (action.type === GET_HEROS_BEGIN) {
        return { ...state, isLoading: true, showAlert: false };
    }

    if (action.type === GET_HEROS_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            heros: action.payload.heros,
            totalHeros: action.payload.totalHeros,
            numOfPages: action.payload.numOfPages,
        };
    }

    if (action.type === GET_CLIENT_HEROS_BEGIN) {
        return { ...state, isLoading: true, showAlert: false };
    }

    if (action.type === GET_CLIENT_HEROS_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            clientHeros: action.payload.clientHeros,
            totalHeros: action.payload.totalHeros,
            numOfPages: action.payload.numOfPages,
        };
    }

    if (action.type === SET_EDIT_HERO) {
        const hero = state.heros.find((hero) => hero._id === action.payload.id);
        const { _id, herotitle, herotext, herolink } = hero;
        return {
            ...state,
            isEditing: true,
            editHeroId: _id,
            herotitle, herotext, herolink
        };
    }

    if (action.type === DELETE_HERO_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === DELETE_HERO_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }

    if (action.type === EDIT_HERO_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === EDIT_HERO_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'success',
            alertText: 'Hero Updated!',
        };
    }

    if (action.type === EDIT_HERO_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }
    // HOME HERO ENDS




    // BLOG
    if (action.type === CLEAR_BLOG_VALUES) {
        const initialState = {
            isEditing: false,
            editBlogId: '',
            title: '',
            subtitle: '',
            author: '',
            description: '',
            fulltext: '',
            fulltexttwo: '',
            tagone: '',
            tagtwo: '',
            tagthree: '',
            tagfour: '',
        };
        return {
            ...state,
            ...initialState,
        };
    }

    if (action.type === CREATE_BLOG_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === CREATE_BLOG_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'success',
            alertText: 'New Blog Created!',
        };
    }

    if (action.type === CREATE_BLOG_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }

    if (action.type === GET_BLOGS_BEGIN) {
        return { ...state, isLoading: true, showAlert: false };
    }

    if (action.type === GET_BLOGS_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            blogs: action.payload.blogs,
            totalBlogs: action.payload.totalBlogs,
            numOfPages: action.payload.numOfPages,
        };
    }

    if (action.type === GET_CLIENT_BLOGS_BEGIN) {
        return { ...state, isLoading: true, showAlert: false };
    }

    if (action.type === GET_CLIENT_BLOGS_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            clientBlogs: action.payload.clientBlogs,
            totalBlogs: action.payload.totalBlogs,
            numOfPages: action.payload.numOfPages,
        };
    }


    if (action.type === SET_EDIT_BLOG) {
        const blog = state.blogs.find((blog) => blog._id === action.payload.id);
        const { _id, title, subtitle, author, description, fulltext, fulltexttwo, tagone, tagtwo, tagthree, tagfour } = blog;
        return {
            ...state,
            isEditing: true,
            editBlogId: _id,
            title, subtitle, author, description, fulltext, fulltexttwo, tagone, tagtwo, tagthree, tagfour
        };
    }

    if (action.type === DELETE_BLOG_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === DELETE_BLOG_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }

    if (action.type === EDIT_BLOG_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === EDIT_BLOG_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'success',
            alertText: 'blog Updated!',
        };
    }

    if (action.type === EDIT_BLOG_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }


    // FAQ START
    if (action.type === CLEAR_FAQ_VALUES) {
        const initialState = {
            isEditing: false,
            editFaqId: '',
            faqtitle: '',
            faqtext: '',
        };
        return {
            ...state,
            ...initialState,
        };
    }

    if (action.type === CREATE_FAQ_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === CREATE_FAQ_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'success',
            alertText: 'New FAQ Created!',
        };
    }

    if (action.type === CREATE_FAQ_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }

    if (action.type === GET_FAQS_BEGIN) {
        return { ...state, isLoading: true, showAlert: false };
    }

    if (action.type === GET_FAQS_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            faqs: action.payload.faqs,
            totalFaqs: action.payload.totalFaqs,
            numOfPages: action.payload.numOfPages,
        };
    }

    if (action.type === GET_CLIENT_FAQS_BEGIN) {
        return { ...state, isLoading: true, showAlert: false };
    }

    if (action.type === GET_CLIENT_FAQS_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            clientFaqs: action.payload.clientFaqs,
            totalFaqs: action.payload.totalFaqs,
            numOfPages: action.payload.numOfPages,
        };
    }


    if (action.type === SET_EDIT_FAQ) {
        const faq = state.faqs.find((faq) => faq._id === action.payload.id);
        const { _id, faqtitle, faqtext } = faq;
        return {
            ...state,
            isEditing: true,
            editFaqId: _id,
            faqtitle, faqtext
        };
    }

    if (action.type === DELETE_FAQ_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === DELETE_FAQ_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }

    if (action.type === EDIT_FAQ_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === EDIT_FAQ_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'success',
            alertText: 'FAQ Updated!',
        };
    }

    if (action.type === EDIT_FAQ_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }

    // FAQ ENDS



    // WORKER
    if (action.type === CLEAR_WORKER_VALUES) {
        const initialState = {
            isEditing: false,
            editWorkerId: '',
            workersname: '',
            workersposition: '',
            workerstext: '',
        };
        return {
            ...state,
            ...initialState,
        };
    }

    if (action.type === CREATE_WORKER_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === CREATE_WORKER_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'success',
            alertText: 'New Worker Created!',
        };
    }

    if (action.type === CREATE_WORKER_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }

    if (action.type === GET_WORKERS_BEGIN) {
        return { ...state, isLoading: true, showAlert: false };
    }

    if (action.type === GET_WORKERS_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            workers: action.payload.workers,
            totalWorkers: action.payload.totalWorkers,
            numOfPages: action.payload.numOfPages,
        };
    }

    if (action.type === GET_CLIENT_WORKERS_BEGIN) {
        return { ...state, isLoading: true, showAlert: false };
    }

    if (action.type === GET_CLIENT_WORKERS_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            clientWorkers: action.payload.clientWorkers,
            totalWorkers: action.payload.totalWorkers,
            numOfPages: action.payload.numOfPages,
        };
    }


    if (action.type === SET_EDIT_WORKER) {
        const worker = state.workers.find((worker) => worker._id === action.payload.id);
        const { _id, workersname, workersposition, workerstext } = worker;
        return {
            ...state,
            isEditing: true,
            editWorkerId: _id,
            workersname, workersposition, workerstext
        };
    }

    if (action.type === DELETE_WORKER_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === DELETE_WORKER_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }

    if (action.type === EDIT_WORKER_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === EDIT_WORKER_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'success',
            alertText: 'Worker Updated!',
        };
    }

    if (action.type === EDIT_WORKER_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }


    // EMAIL SECTION STARTS

    if (action.type === CLEAR_EMAIL_VALUES) {
        const initialState = {
            isEditing: false,
            editEmailId: '',
            emailname: '',
            emailcomponent: '',
            emailtext: '',
        };
        return {
            ...state,
            ...initialState,
        };
    }

    if (action.type === DELETE_EMAIL_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === DELETE_EMAIL_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }

    if (action.type === CREATE_EMAIL_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === CREATE_EMAIL_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'success',
            alertText: 'New EMAIL Created!',
        };
    }

    if (action.type === CREATE_EMAIL_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }

    if (action.type === GET_EMAILS_BEGIN) {
        return { ...state, isLoading: true, showAlert: false };
    }

    if (action.type === GET_EMAILS_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            emails: action.payload.emails,
            totalEmails: action.payload.totalEmails,
            numOfPages: action.payload.numOfPages,
        };
    }

    if (action.type === GET_CLIENT_EMAILS_BEGIN) {
        return { ...state, isLoading: true, showAlert: false };
    }

    if (action.type === GET_CLIENT_EMAILS_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            clientEmails: action.payload.clientEmails,
            totalEmails: action.payload.totalEmails,
            numOfPages: action.payload.numOfPages,
        };
    }

    if (action.type === SHOW_STATSEMAIL_BEGIN) {
        return { ...state, isLoading: true, showAlert: false };
    }

    if (action.type === SHOW_STATSEMAIL_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            statsEmail: action.payload.statsEmail,
            monthlyApplicationsEmail: action.payload.monthlyApplicationsEmail,
        };
    }


    if (action.type === SET_EDIT_EMAIL) {
        const email = state.emails.find((email) => email._id === action.payload.id);
        const { _id, statusemail } = email;
        return {
            ...state,
            isEditing: true,
            editEmailId: _id,
            statusemail
        };
    }

    if (action.type === EDIT_EMAIL_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === EDIT_EMAIL_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'success',
            alertText: 'Email status Updated!',
        };
    }

    if (action.type === EDIT_EMAIL_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }

    // EMAIL SECTION ENDS




     // SUB SECTION STARTS

    if (action.type === CLEAR_SUB_VALUES) {
        const initialState = {
            isEditing: false,
            editSubId: '',
            subscribeEmail: '',
            status: 'subscribe',
        };
        return {
            ...state,
            ...initialState,
        };
    }

    if (action.type === CREATE_SUB_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === CREATE_SUB_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'success',
            alertText: 'Request Succeded!',
        };
    }

    if (action.type === CREATE_SUB_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }

    if (action.type === GET_SUBS_BEGIN) {
        return { ...state, isLoading: true, showAlert: false };
    }

    if (action.type === GET_SUBS_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            subs: action.payload.subs,
            totalSubs: action.payload.totalSubs,
            numOfPages: action.payload.numOfPages,
        };
    }

    if (action.type === GET_CLIENT_SUBS_BEGIN) {
        return { ...state, isLoading: true, showAlert: false };
    }

    if (action.type === GET_CLIENT_SUBS_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            clientSubs: action.payload.clientSubs,
            totalSubs: action.payload.totalSubs,
            numOfPages: action.payload.numOfPages,
        };
    }


    if (action.type === DELETE_SUB_BEGIN) {
        return { ...state, isLoading: true };
    }

    if (action.type === DELETE_SUB_ERROR) {
        return {
            ...state,
            isLoading: false,
            showAlert: true,
            alertType: 'danger',
            alertText: action.payload.msg,
        };
    }

    // SUB SECTION ENDS


    throw new Error(`no such action :${action.type}`);
};

export default reducer;