import * as React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import { Button, Container } from "react-bootstrap";
import { BsChatQuote } from "react-icons/bs";
import { useAppContext } from '../../context/appContext';
import { FormRow, Alert, FormRowSelect } from '../../components';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


import "../../styles/CTA.css";

// Modal style
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '1200px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};


function ChildModal() {

    // For the sub CRUD
    const { isLoading, createSub, displayAlert, subscribeEmail, handleChange, status, statusOptions, } = useAppContext();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!subscribeEmail) {
            displayAlert()
            return
        }
        createSub();
    }

    const handleSubInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        handleChange({ name, value })
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Container style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Button onClick={handleOpen} style={{borderColor:' #3b9fe2 !important', backgroundColor: 'white', color: '#3b9fe2'}}>Remove my email</Button>
            </Container>
            <Modal open={open} onClose={handleClose} aria-labelledby="child-modal-title" aria-describedby="child-modal-description" >
                <Box sx={{ ...style, maxWidth: '700px', minWidth: '300px' }}>
                <h3 className='text-center' style={{color:'black', fontFamily: 'Roboto', fontWeight: 'bold'}}>Remove my email</h3>
                    <hr></hr>
                    <p className='text-center py-2 my-2'>Choose whether to be added or removed from our email list</p>
                    <Container fluid style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {/* Sub status */}
                        <FormRowSelect name='status' value={status} handleChange={handleSubInput} list={statusOptions} />
                    </Container>
                    <InputGroup className="mb-3" id="sub-input" style={{display:'flex', justifyContent:'center', alignItems:'center', fontSize:'20px', padding:'10px'}}>
                        {/* Email*/}
                        <div className="subemail" style={{ maxWidth:'500px'}}>
                            <FormRow
                            style={{borderTopLeftRadius:30, borderBottomLeftRadius:30}}
                            className="text-dark"
                            placeholder='JohnDoe@gmail.com'
                            type='text'
                            name='subscribeEmail'
                            value={subscribeEmail}
                            handleChange={handleSubInput}
                            />
                        </div>
                        <Button className="inputgroup-button" style={{fontSize: '14px', borderColor:'#3b9fe2', color:'#3b9fe2', backgroundColor:'white', borderTopRightRadius:30, borderBottomRightRadius:30, maxHeight:'35px',}} variant="outline-dark" disabled={isLoading} onClick={handleSubmit}>
                            
                            Confirm change
                        </Button>
                    </InputGroup>
                    <Container fluid style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Button onClick={handleClose}>return to subscribe</Button>
                    </Container>
                </Box>
            </Modal>
        </React.Fragment>
    );
}


export default function SignUp() {

    // For the sub CRUD
    const { isLoading, createSub, showAlert, displayAlert, subscribeEmail, handleChange, } = useAppContext();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!subscribeEmail) {
            displayAlert()
            return
        }
        createSub();
    }

    const handleSubInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        handleChange({ name, value })
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container fluid>
            <div className='signup-btn-body'>
                <Button className="ctasignup" size="lg" onClick={handleOpen} style={{backgroundColor: '#F28C28', borderWidth: '0px'}}>Subscribe</Button>
            </div>
            <Modal open={open} onClose={handleClose} disableScrollLock={true}  >
                <Box sx={{ ...style, maxWidth: '1200px', minWidth: '300px' }}>
                <h3 className='text-center' style={{color:'black', fontFamily: 'Roboto', fontWeight: 'bold'}}>Do you want to learn more?</h3>
                <hr></hr>
                <p className='text-center' style={{fontWeight:'bold', fontFamily: 'Roboto'}}>Enter you e-mail and we will get in touch with you</p>
                <p className='text-center' style={{fontWeight:'lighter', fontFamily: 'Roboto'}}>We promise not to share or abuse your information</p>
                    {showAlert && <Alert />}
                    <InputGroup className="mb-3" id="sub-input" style={{display:'flex', justifyContent:'center', alignItems:'center', fontSize:'16px'}}>
                        {/* Email*/}
                        <div className="subemail" style={{ maxWidth:'500px'}}>
                            <FormRow
                                style={{borderTopLeftRadius:30, borderBottomLeftRadius:30}}
                                className="text-dark"
                                placeholder='example@gmail.com'
                                type='text'
                                name='subscribeEmail'
                                value={subscribeEmail}
                                handleChange={handleSubInput}
                                />
                        </div>
                        <Button className="inputgroup-button" style={{fontSize: '14px', fontFamily: 'roboto',borderColor: 'white', color:'white', backgroundColor:'#3b9fe2', borderTopRightRadius:30, borderBottomRightRadius:30, maxHeight:'35px'}} variant="outline-dark" disabled={isLoading} onClick={handleSubmit}>
                        <BsChatQuote size={18} style={{marginBottom:4, marginRight:5}} /> 
                            Contact me
                        </Button>
                    </InputGroup>
                    <hr></hr>
                    <p className='text-center text-secondary' style={{fontFamily: 'roboto'}}>Click below to remove your email from our list</p>
                    <ChildModal />
                </Box>
            </Modal>
        </Container>
    );
};