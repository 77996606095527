
import React from 'react';
import '../styles/About.css';
import heroimg from "../img/bg_container1.png";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ModalEmail from '../components/CTA/ModalEmail';
import ScrollButton from '../components/CTA/ScrollButton';
import WorkersContainer from '../components/WorkersContainer';
import Vision from '../components/About/Vision';
import AboutUs from '../components/About/AboutUs';
// import OurProjects from '../components/About/OurProjects';
//import TechKey from '../components/About/TechKey';


function About() {
    return (
        <Container fluid style={{paddingLeft:0, paddingRight:0}}>
            <div className="About-Page">     
                
                {/* HERO SECTION STARTS */}
                <div className='hero-section'>
                    <Row style={{ marginLeft:0, marginRight:0, }}>
                        <Col sm={12} md={12} lg={12} xl={12} style={{paddingLeft:0, paddingRight:0, }}>
                            <figure className='position-relative'>
                                <img src={heroimg} alt="heroimg" />
                            </figure>
                        </Col>
                    </Row>
                </div>



                {/* VISION SECTION STARTS */}
                <div className='vision-section' id="vision">
                    <Row style={{ marginLeft:0, marginRight:0, }}>
                        <Col sm={12} md={12} lg={12} xl={12} style={{ paddingLeft:0, paddingRight:0, }}>
                            <div className='vision-h2 text-center'>
                                <h2 style={{fontFamily: 'Montserrat'}}>Our vision</h2>
                                <h5 className='text-secondary' style={{marginTop:20, fontFamily: 'Montserrat'}}>Empower experts as well as non-experts with atomic simulation tools</h5>
                                <hr></hr>
                            </div>
                        </Col>
                    </Row>
                    <Vision />
                </div>


                {/* TECHNOLOGY KEYPOINTS STARTS  */}
                
                {/*
                <TechKey />
                */}

                {/* TECHNOLOGY KEYPOINTS ENDS */}

                

                {/* ABOUT US SECTION STARTS */}
                
                <AboutUs />

                {/* ABOUT US SECTION ENDS */}
                


                {/* SCIENTIST SECTION STARTS */}
                <div className='py-4' style={{marginTop:60, marginBottom:'40px'}}>
                    <Row className='justify-content-center' style={{marginLeft: 0, marginRight: 0}}>
                        <Col sm={10} md={10} lg={10} style={{paddingLeft:0, paddingRight:0}}>
                            <div className='text-center' style={{marginTop:50}}>
                                <h2 style={{fontFamily: 'Montserrat'}}>Board of Scientists</h2>
                                <h5 className='text-secondary' style={{fontFamily: 'Montserrat'}}>The people that ensure the quality of our tools</h5>
                            </div>
                            <div className='worker'>
                                <React.Fragment>
                                    <Container style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        {/* Only for frontend API */}
                                        <WorkersContainer isWorkerPage={true} />
                                    </Container>
                                </React.Fragment>
                            </div>
                        </Col>
                    </Row>
                </div>
                {/* SCIENTIST SECTION ENDS */}


                {/* OUR PROJECT SECTION STARTS */}

                {/*
                    <OurProjects />
                 */}
                

                {/* OUR PROJECT SECTION ENDS */}

                

            <ModalEmail/>
            <ScrollButton/>

            </div>
        </Container> 
    );
}


export default About;