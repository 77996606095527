
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../context/appContext';
import { Button, Col, Container, InputGroup, Row, Card } from 'react-bootstrap';
import Video from '../components/Forside/Video';
import { FormRow, Alert, FormRowSelect } from '../components';
import { BsChatQuote } from 'react-icons/bs';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import '../styles/Home.css';
import '../styles/CTA.css';
import he from 'he';


// Modal style
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '1200px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};


// NESTED MODAL
function ChildModal() {

    // For the sub CRUD
    const { isLoading, createSub, displayAlert, subscribeEmail, handleChange, status, statusOptions, } = useAppContext();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!subscribeEmail) {
            displayAlert()
            return
        }
        createSub();
    }

    const handleSubInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        handleChange({ name, value })
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Container style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Button onClick={handleOpen} style={{borderColor:' #3b9fe2 !important', backgroundColor: 'white', color: '#3b9fe2'}}>Remove my email</Button>
            </Container>
            <Modal open={open} onClose={handleClose} aria-labelledby="" aria-describedby="child-modal-description" >
                <Box sx={{ ...style, maxWidth: 700, minWidth: '300px' }}>
                    <h3 className='text-center' style={{color:'black', fontFamily: 'Roboto', fontWeight: 'bold'}}>Remove my email</h3>
                    <hr></hr>
                    <p className='text-center py-2 my-2'>Choose whether to be added or removed from our email list</p>
                    <Container fluid style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {/* Sub status */}
                        <FormRowSelect name='status' value={status} handleChange={handleSubInput} list={statusOptions} />
                    </Container>
                    <InputGroup className="mb-3" id="sub-input" style={{display:'flex', justifyContent:'center', alignItems:'center', fontSize:'16px', padding:'10px'}}>
                        {/* Email*/}
                        <div className="subemail" style={{ maxWidth:'500px'}}>
                            <FormRow
                            style={{borderTopLeftRadius:30, borderBottomLeftRadius:30}}
                            className="text-dark"
                            placeholder='example@gmail.com'
                            type='text'
                            name='subscribeEmail'
                            value={subscribeEmail}
                            handleChange={handleSubInput}
                            />
                        </div>
                        <Button className="inputgroup-button" style={{fontSize: '14px', borderColor:'#3b9fe2', color:'#3b9fe2', backgroundColor:'white', borderTopRightRadius:30, borderBottomRightRadius:30, maxHeight:'35px',}} variant="outline-dark" disabled={isLoading} onClick={handleSubmit}> 
                            Confirm change
                        </Button>
                    </InputGroup>
                    <Container fluid style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Button onClick={handleClose}>return to subscribe</Button>
                    </Container>
                </Box>
            </Modal>
        </React.Fragment>
    );
}


// FOR MODAL
function HeroModal(props) {

    // For the sub CRUD
    const { isLoading, createSub, showAlert, displayAlert, subscribeEmail, handleChange, } = useAppContext();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!subscribeEmail) {
            displayAlert()
            return
        }
        createSub();
    }

    const handleSubInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        handleChange({ name, value })
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container>
            <div onClick={handleOpen}>Get started</div>
            <Modal open={open} onClose={handleClose} disableScrollLock={true}  >
                <Box sx={{ ...style, maxWidth: '1200px', minWidth: '300px' }}>
                    <h3 className='text-center' style={{color:'black', fontFamily: 'Roboto', fontWeight: 'bold'}}>Do you want to learn more?</h3>
                    <hr></hr>
                    
                    <p className='text-center' style={{fontWeight:'bold', fontFamily: 'Roboto'}}>Enter you e-mail and we will get in touch with you</p>
                    <p className='text-center' style={{fontWeight:'lighter', fontFamily: 'Roboto'}}>We promise not to share or abuse your information</p>
                    {showAlert && <Alert />}
                    <InputGroup className="mb-3" id="sub-input" style={{display:'flex', justifyContent:'center', alignItems:'center', fontSize:'16px'}}>
                        {/* Email*/}
                        <div className="subemail" style={{ maxWidth:'500px'}}>
                            <FormRow
                                style={{borderTopLeftRadius:30, borderBottomLeftRadius:30}}
                                className="text-dark"
                                placeholder='example@gmail.com'
                                type='text'
                                name='subscribeEmail'
                                value={subscribeEmail}
                                handleChange={handleSubInput}
                                />
                        </div>
                        <Button className="inputgroup-button" style={{fontSize: '14px', fontFamily: 'roboto',borderColor: 'white', color:'white', backgroundColor:'#3b9fe2', borderTopRightRadius:30, borderBottomRightRadius:30, maxHeight:'35px'}} variant="outline-dark" disabled={isLoading} onClick={handleSubmit}>
                            <BsChatQuote size={18} style={{marginBottom:4, marginRight:5}} /> 
                            Contact me
                        </Button>
                    </InputGroup>
                    <hr></hr>
                    <p className='text-center text-secondary' style={{fontFamily: 'roboto'}}>Click below to remove your email from our list</p>
                    <ChildModal />
                </Box>
            </Modal>
        </Container>
    );
}
//


const Hero = ({ _id, herotitle, herotext, herolink, isHeroPage }) => {
    
    const [modalShow, setModalShow] = useState(false);

    const { setEditHero, deleteHero } = useAppContext()

    return (

        <div className='' style={{ }}>
            <div className='hero-editbtn'>
            { isHeroPage ? (<>
                    </>) : <>
                        <footer>
                            <div className='actions'>
                                <Link
                                    to='/heroadmin'
                                    className='btn edit-btn btn-success'
                                    onClick={() => setEditHero(_id)}
                                >
                                    Edit
                                </Link>
                                <button
                                    type='button'
                                    className='btn delete-btn btn-danger mx-2'
                                    onClick={() => deleteHero(_id)}
                                >
                                    Delete
                                </button>
                            </div>
                        </footer>
                    </>}
            </div>
            
            <div className='hero-wrapper'>
                <Row style={{ marginLeft: 0, marginRight: 0}}>
                    <Col sm={12} md={12} lg={4} xl={4} style={{paddingLeft:0, paddingRight:0}}>
                        <div className='hero_info'>
                                <div className='hero-title pt-4 px-2'>
                                    <h1 dangerouslySetInnerHTML={{ __html: he.decode(herotitle) }}></h1>
                                </div>
                                <div className='hero-tekst pt-4 px-2'>
                                    <p>
                                        {herotext}
                                    </p>
                                </div>
                                <div className='hero-btn-body px-4 pt-2 pb-2' style={{display:'flex', justifyContent: 'left', alignItems: 'start', gap: '10px'}}>
                                    <Button variant='secondary' className="ctahero" size="lg" style={{borderWidth: '0px'}}>
                                        <HeroModal show={modalShow} onHide={() => setModalShow(false)} />
                                    </Button>
                                    {/* <Button href='https://nanoscale.onrender.com/products' variant="outline-light" size="lg" style={{borderRadius:30, maxWidth:300, color: 'white', backgroundColor: '#3b9fe2'}}>
                                        Discover more
                                    </Button> */}
                                </div>
                                <div className='hero-link mt-8 mx-4 mb-2' style={{paddingTop: '30px'}}>
                                    <i className="fa fa-newspaper px-2 display:inline-block" style={{display:'inline-block'}}></i>
                                    <p  style={{fontFamily:'Roboto', display:'inline-block', fontWeight: 'bold'}}>Read Article:</p>
                                    <Card.Link href={herolink} style={{fontFamily:'Roboto', display:'block', fontWeight:'bold', marginTop:'-15px'}}>
                                        "School Students in Action, in a Simulated Nano World"<MdOutlineKeyboardArrowRight size={22}/>
                                    </Card.Link>
                                </div>
                            </div>
                    </Col>

                    <Col className='align-self-center' sm={12} md={12} lg={8} xl={8} style={{paddingLeft:0, paddingRight:0}}>
                        <div className='hero-video'>
                            <Video/>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>

    );
};

export default Hero;