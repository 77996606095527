

import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
// import Stack from 'react-bootstrap/Stack';
import '../../styles/Home.css';
import '../../styles/CTA.css';


const UserCaseRight = () => {

    return (
        <div className="usercase my-4">
            <Container>
                <div className="usercase-wrapper2 my-4">
                    <Card className="shadow-lg" style={{borderRadius:20}}>
                        <Row>
                            <Col xs={{span: 12, order: 1}} md={{span: 6, order: 1, offset:3}} lg={{span: 8, order: 1, offset:0}}>
                                <Image className="p-4" src={require('../../img/bg_container1.png')} alt="llalal" style={{maxHeight:500}} ></Image>
                            </Col>
                            <Col xs={{span: 12, order: 2}} md={{span: 12, order: 2}} lg={{span: 4, order: 2, offset:0}}>
                                <div className="usercase-info p-4">
                                    <h4 className="blog-card-caption" style={{color:'black', fontFamily: 'roboto'}}>VR Workshops</h4>
                                    <hr></hr>
                                    <h5 style={{fontFamily: 'Roboto', lineHeight: '1.2', paddingBottom: '6px', fontWeight: 'bold'}}>For educators and students</h5>
                                    <p style={{fontFamily: 'Roboto', fontSize: '16px', lineHeight: '1.6'}}>
                                    Are you interested in learning how our simulations can help you educate your students in atomic science?
                                    Book an interactive workshop with us, and we will bring our tools to you and give you and your
                                    students a hands-on experience with our learning tools.
                                    {/* </p> */}
                                    {/* <p style={{fontFamily: 'Roboto', fontSize: '16px', lineHeight: '1.6',}}> */}
                                    <br/>
                                    We also do workshops for educators only, where we give an introduction into how
                                    our simulations can help your students perform better. 
                                    </p>
                                    <hr></hr>
                                    {/* <Stack className='usercase-stack text-center' direction="horizontal" gap={2} style={{display: 'flex', justifyContent: 'start', alignItems: 'start'}}>
                                        <div className="p-2 bg-primary rounded-pill" style={{color:'white'}}>Virtual Reality</div>
                                        <div className="p-2 bg-secondary rounded-pill" style={{color:'white'}}>Simulations</div>
                                        <div className="p-2 bg-primary rounded-pill" style={{color:'white'}}>Academia</div>
                                    </Stack> */}
                                    <Container style={{display: 'flex', justifyContent: 'start', alignItems: 'start', paddingLeft: '0px'}}>
                                        <div className='value-more-button pt-4'>
                                            <Button href="/Products" className='hero-btn-body'>Learn More</Button>
                                        </div>
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Container>
        </div>
    );

}

export default UserCaseRight;
