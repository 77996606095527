import React from "react";
import { Ratio } from 'react-bootstrap';
//import ReactPlayer from 'react-player';
// import myVideo from './MegaGrant.mp4';
import '../../styles/Home.css';


//controls width="100%"

//const src = "https://youtu.be/mINwpCkegwo?si=fmlL0dAnQ0rUDMS7";
// const yt = "https://www.youtube.com/embed/Yo3AcnSr6_g";

const Video = () => {

    return (
        <div className='video-wrapper' style={{paddingTop: 30, paddingLeft: '20px', paddingRight: '20px', paddingBottom: 30}}>
            
            <Ratio aspectRatio="16x9" style={{borderRadius: '10px', zIndex: '1', overflow: 'hidden'}}>
                <iframe src="https://www.youtube.com/embed/mINwpCkegwo?autoplay=1"  title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </Ratio>
        
            {/*
            <ReactPlayer
            url={myVideo}
            controls={false}
            playing={true}
            loop={true}
            volume={null}
            muted={true}
            width={'100%'}
            height={'100%'}
            />
            */}

        </div>
    );

};

export default Video;