
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import posthog from 'posthog-js';
// Dashbord pages
import { Profile, Stats, SharedLayout, Geography, FaqAdmin, Blogs, Workers, EmailAdmin, SubAdmin, HeroAdmin, } from './pages/dashboard';
// Components
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
// Frontend Pages
import Home from './pages/Home';
import About from './pages/About';
import Products from "./pages/Products";
import WebBlog from './pages/WebBlog';
import Register from './pages/Register';
import Error from './pages/Error';
import ProtectedRoute from './pages/ProtectedRoute';
import ReferenceList from './pages/Reference';
import CookieBanner from './CookieBanner';
import { Contact } from './pages';

// GA4
ReactGA.initialize("G-6BP4ZEPV1X");


function App() {


  // PROCESS BAR
  const [scrollTop, setScrollTop] = useState(0);
  const onScroll = () => {
      // This will calculate how many pixels the page is vertically
      const winScroll = document.documentElement.scrollTop;
      // This is responsible for subtracticing the total height of the page - where the users page is scrolled to
      const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight;
      // This will calculate the final total of the percentage of how much the user has scrolled.
      const scrolled = (winScroll / height) * 100;
      setScrollTop(scrolled);
  };
  useEffect(() => {
      // Fires when the document view has been scrolled
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
  }, []);
  //

  ReactGA.send({ hitType: "pageview", page: "/about", title: "About page" });
  ReactGA.send({ hitType: "pageview", page: "/landing", title: "Landing page" });
  ReactGA.send({ hitType: "pageview", page: "/products", title: "Products Page" });
  ReactGA.send({ hitType: "pageview", page: "/blog", title: "Blog page" });
  
  return (
    <div className="App" >

      <div className="progressMainWrapper">
        <div className="progressMainStyle" style={{ width: `${scrollTop}%`}}></div>
      </div>
      
      <Navbar />

      <div style={{position: 'relative', zIndex: 99, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        {posthog.has_opted_out_capturing() 
        ||posthog.has_opted_in_capturing() 
          ? null 
          : <CookieBanner /> 
        }
      </div>
    
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<ProtectedRoute><SharedLayout /></ProtectedRoute>} >
            {/* The first page stats */}
            <Route index element={<Stats />} />
            <Route path='profile' element={<Profile />}></Route>
            <Route path='geography' element={<Geography />} />
            <Route path='blogs' element={<Blogs />} />
            <Route path='faqadmin' element={<FaqAdmin />} />
            <Route path='workers' element={<Workers />} />
            <Route path='emailadmin' element={<EmailAdmin />} />
            <Route path='subadmin' element={<SubAdmin />} />
            <Route path='heroadmin' element={<HeroAdmin />} />
          </Route>
          <Route exact path='/landing' element={<Home />} />
          <Route path='/blog' element={<WebBlog />} />
          <Route path='/products' element={<Products />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/references' element={<ReferenceList />} />
          <Route path='/login' element={<Register />} />
          <Route path='*' element={<Error />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>

  );

}

export default App;
