/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Card,  Image, ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import { BsArrowRight, BsFillCheckCircleFill } from 'react-icons/bs';




export default function Prisliste() {


    return (
        <div className=''>

            <div className="container">
                <div className="tab-content wow fadeIn">
                    <div role="tabpanel" className="tab-pane fade show active" id="yearly">
                        <div className="row justify-content-center" style={{gap: '100px'}}>

                        {/* <div className="col-md-6 col-lg-4 mb-4">
                                <div className="price-item text-center popular">
                                    <div className="price-top">
                                        <div className="pricing-badge">
                                            <div className="badge">Simulation</div>
                                        </div>
                                        <div className="prisliste-img text-center" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            <Image roundedCircle alt="2pacShakur" src={require('../../img/NanoCalc.PNG')} style={{maxHeight:'130px'}}></Image>
                                        </div>
                                        <h4 className='pt-2' style={{fontFamily: 'Montserrat'}}>Online atomic calculator</h4>
                                        <Card.Subtitle className="text-center mt-2">
                                            <p className='text-dark'></p>            
                                        </Card.Subtitle>
                                        <h2 className="mb-0" style={{fontFamily: 'Montserrat'}}><sup></sup>NanoCalc</h2>
                                        <span className="text-capitalize"></span>
                                    </div>
                                    <div className="price-content">
                                        <div className="border-bottom mb-4 mt-md-4 pb-3 text-left text-light" style={{listStyle:'none'}}>
                                            <h5 style={{fontFamily: 'Montserrat', fontWeight: 'bold'}}>Features</h5>
                                            <li>
                                                <i className="zmdi zmdi-check mr-2"></i>
                                                <p className="mb-2"><BsFillCheckCircleFill color='green'/> <b>No code</b></p>
                                            </li>
                                            <li>
                                                <i className="zmdi zmdi-check mr-2"></i>
                                                <p className="mb-2"><BsFillCheckCircleFill color='green'/> <b>No installations</b></p>
                                            </li>
                                            <li>
                                                <i className="zmdi zmdi-check mr-2"></i>
                                                <p className="mb-2"><BsFillCheckCircleFill color='green'/> <b>Easy to use</b></p>
                                            </li>
                                            <li>
                                                <i className="zmdi zmdi-close mr-2"></i>
                                                <p className="mb-2"><BsFillCheckCircleFill color='green'/> <b>Run calculations on the cloud</b></p>
                                            </li>
                                            <li>
                                                <i className="zmdi zmdi-close mr-2"></i>
                                                <p className="mb-2"><BsFillCheckCircleFill color='green'/> <b>State-of-the-art methods</b></p>
                                            </li>
                                            <li>
                                                <i className="zmdi zmdi-close mr-2"></i>
                                                <p className="mb-2"><BsFillCheckCircleFill color='green'/> <b>Take control of your calculations</b></p>
                                            </li>
                                        </div>
                                        <Card.Body>
                                            <div className="prem-policy text-center">
                                                <ListGroup >
                                                    <ListGroupItem className="text-dark"><BsArrowRight/> Run Atomic Calculations Online</ListGroupItem>
                                                </ListGroup>
                                            </div>
                                        </Card.Body>
                                        <div className="text-center mt-4">
                                            <Button className="free-btn" variant='outline-light' href="http://www.example.com/theme.css" style={{fontWeight:'bold', letterSpacing:1}}>Go to NanoCalc
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-6 col-lg-4 mb-4">
                                <div className="price-item text-center">
                                    <div className="price-top">
                                        <div className="pricing-badge">
                                            <div className="badge">STEM</div>
                                        </div>
                                        <div className="prisliste-img text-center" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            <Image src={require('../../img/NanoCraft1.PNG')} style={{maxHeight:200, borderRadius:'10px'}}></Image>
                                        </div>
                                        <h4 className='pt-2 product-value'>Learn science with simulations</h4>
                                        <Card.Subtitle className="text-center mt-2">
                                            <p></p>
                                        </Card.Subtitle>
                                        <h2 className="mb-0 product-title"><sup></sup>NanoCraft VR</h2>
                                        <span className="text-capitalize"></span>
                                    </div>
                                    <div className="price-content">
                                        <div className="border-bottom mb-4 mt-md-4 pb-3 text-left" style={{listStyle:'none'}}>
                                        <h5 style={{fontFamily: 'Roboto', fontWeight: 'bold'}}>Includes</h5>
                                            <li>
                                                <i className="zmdi zmdi-close mr-2"></i>
                                                <p className="mb-2 product-feature"><BsFillCheckCircleFill color='green'/> <b>Tools for building molecules</b></p>
                                            </li>
                                            <li>
                                                <i className="zmdi zmdi-check mr-2"></i>
                                                <p className="mb-2 product-feature"><BsFillCheckCircleFill color='green'/> <b>Atomic interactions</b></p>
                                            </li>
                                            <li>
                                                <i className="zmdi zmdi-check mr-2"></i>
                                                <p className="mb-2 product-feature"><BsFillCheckCircleFill color='green'/> <b>Chemical bonding</b></p>
                                            </li>
                                            <li>
                                                <i className="zmdi zmdi-check mr-2"></i>
                                                <p className="mb-2 product-feature"><BsFillCheckCircleFill color='green'/> <b>Interactive periodic table</b></p>
                                            </li>
                                            <li>
                                                <i className="zmdi zmdi-close mr-2"></i>
                                                <p className="mb-2 product-feature"><BsFillCheckCircleFill color='green'/> <b>Tools for exploring properties</b></p>
                                            </li>
                                            <li>
                                                <i className="zmdi zmdi-close mr-2"></i>
                                                <p className="mb-2 product-feature"><BsFillCheckCircleFill color='green'/> <b>Models for inquiry based learning</b></p>
                                            </li>
                                        </div>
                                        <Card.Body>
                                            <div className="prem-policy text-center">
                                                <ListGroup >
                                                    <ListGroupItem className="text-light" style={{backgroundColor:'#3C9FE1', fontFamily: 'roboto'}}> Use simulations as models for inquiry</ListGroupItem>
                                                </ListGroup>
                                            </div>
                                        </Card.Body>
                                        <div className="text-center mt-4">
                                            <Button className="prem-btn" variant='outline-primary' href="" style={{fontWeight:'bold', letterSpacing:1, color:'#3C9FE1'}}>Go To NanoCraft VR
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-4">
                            <div className="price-item text-center">
                                    <div className="price-top">
                                        <div className="pricing-badge">
                                            <div className="badge">Workshop</div>
                                        </div>
                                        <div className="prisliste-img text-center" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            <Image src={require('../../img/workshop.jpeg')} style={{maxHeight:200, borderRadius:'10px'}}></Image>
                                        </div>
                                        <h4 className='pt-2 product-value'>We bring VR for STEM to you</h4>
                                        <Card.Subtitle className="text-center mt-2">
                                            <p></p>
                                        </Card.Subtitle>
                                        <h2 className="mb-0 product-title"><sup></sup>VR STEM Workshop</h2>
                                        <span className="text-capitalize"></span>
                                    </div>
                                    <div className="price-content">
                                        <div className="border-bottom mb-4 mt-md-4 pb-3 text-left" style={{listStyle:'none'}}>
                                        <h5 style={{fontFamily: 'Roboto', fontWeight: 'bold'}}>Includes</h5>
                                            <li>
                                                <i className="zmdi zmdi-close mr-2"></i>
                                                <p className="mb-2 product-feature"><BsFillCheckCircleFill color='green'/> <b>All the equipment</b></p>
                                            </li>
                                            <li>
                                                <i className="zmdi zmdi-check mr-2"></i>
                                                <p className="mb-2 product-feature"><BsFillCheckCircleFill color='green'/> <b>Introduction to VR</b></p>
                                            </li>
                                            <li>
                                                <i className="zmdi zmdi-check mr-2"></i>
                                                <p className="mb-2 product-feature"><BsFillCheckCircleFill color='green'/> <b>Hands-on workshop with students</b></p>
                                            </li>
                                            <li>
                                                <i className="zmdi zmdi-check mr-2"></i>
                                                <p className="mb-2 product-feature"><BsFillCheckCircleFill color='green'/> <b>Integration with curriculum</b></p>
                                            </li>
                                            <li>
                                                <i className="zmdi zmdi-close mr-2"></i>
                                                <p className="mb-2 product-feature"><BsFillCheckCircleFill color='green'/> <b>Introduction for educators</b></p>
                                            </li>
                                            <li>
                                                <i className="zmdi zmdi-close mr-2"></i>
                                                <p className="mb-2 product-feature"><BsFillCheckCircleFill color='green'/> <b>Advice and consulting</b></p>
                                            </li>
                                        </div>
                                        <Card.Body>
                                            <div className="prem-policy text-center">
                                                <ListGroup >
                                                    <ListGroupItem className="text-light" style={{backgroundColor:'#3C9FE1', fontFamily: 'roboto'}}> Get introduced to VR for STEM</ListGroupItem>
                                                </ListGroup>
                                            </div>
                                        </Card.Body>
                                        <div className="text-center mt-4">
                                            <Button className="prem-btn" variant='outline-primary' href="/Contact" style={{fontWeight:'bold', letterSpacing:1, color:'#3C9FE1'}}>
                                                Contact us
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}