
import Form from 'react-bootstrap/Form';


const TextAreaRow = ({ type, name, value, handleChange, labelText, placeholder }) => {

    return (
        <div className='text-area-row'>
            <Form.Control
            placeholder={placeholder}
            type={type}
            value={value}
            name={name}
            onChange={handleChange}
            className='text-area-row' 
            as="textarea" 
            rows={3}
            />
        </div>
    )
};

export default TextAreaRow;