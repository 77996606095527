
import React, { useReducer, useContext, useEffect } from 'react';
import reducer from './reducer';
import {
    // GLOBAL
    DISPLAY_ALERT, CLEAR_ALERT, TOGGLE_SIDEBAR, HANDLE_CHANGE, CLEAR_VALUES, SHOW_STATS_BEGIN, SHOW_STATS_SUCCESS,
    CLEAR_FILTERS, CHANGE_PAGE,  HANDLE_FILE_CHANGE,
    
    // USER
    LOGOUT_USER, SETUP_USER_BEGIN, SETUP_USER_SUCCESS, SETUP_USER_ERROR, UPDATE_USER_BEGIN, UPDATE_USER_SUCCESS, UPDATE_USER_ERROR,
    GET_CURRENT_USER_BEGIN, GET_CURRENT_USER_SUCCESS, 

     // HOME HERO
    GET_HEROS_BEGIN, GET_HEROS_SUCCESS, SET_EDIT_HERO, DELETE_HERO_BEGIN, DELETE_HERO_ERROR, EDIT_HERO_BEGIN, EDIT_HERO_SUCCESS, EDIT_HERO_ERROR, CLEAR_HERO_VALUES,
    
    // BLOG
    CREATE_BLOG_BEGIN, CREATE_BLOG_SUCCESS, CREATE_BLOG_ERROR, GET_BLOGS_BEGIN, GET_BLOGS_SUCCESS, SET_EDIT_BLOG, DELETE_BLOG_BEGIN, DELETE_BLOG_ERROR, EDIT_BLOG_BEGIN, EDIT_BLOG_SUCCESS, EDIT_BLOG_ERROR, CLEAR_BLOG_VALUES,

    // FAQ
    CREATE_FAQ_BEGIN, CREATE_FAQ_SUCCESS, CREATE_FAQ_ERROR, GET_FAQS_BEGIN, GET_FAQS_SUCCESS, SET_EDIT_FAQ, DELETE_FAQ_BEGIN, DELETE_FAQ_ERROR, EDIT_FAQ_BEGIN, EDIT_FAQ_SUCCESS, EDIT_FAQ_ERROR, CLEAR_FAQ_VALUES,

    // WORKER
    CREATE_WORKER_BEGIN, CREATE_WORKER_SUCCESS, CREATE_WORKER_ERROR, GET_WORKERS_BEGIN, GET_WORKERS_SUCCESS, SET_EDIT_WORKER, DELETE_WORKER_BEGIN, DELETE_WORKER_ERROR, EDIT_WORKER_BEGIN, EDIT_WORKER_SUCCESS, EDIT_WORKER_ERROR, CLEAR_WORKER_VALUES,

    // EMAIL
    CREATE_EMAIL_BEGIN, CREATE_EMAIL_SUCCESS, CREATE_EMAIL_ERROR, GET_EMAILS_BEGIN, GET_EMAILS_SUCCESS, CLEAR_EMAIL_VALUES, DELETE_EMAIL_BEGIN, DELETE_EMAIL_ERROR, SHOW_STATSEMAIL_BEGIN, SHOW_STATSEMAIL_SUCCESS, SET_EDIT_EMAIL, EDIT_EMAIL_BEGIN, EDIT_EMAIL_SUCCESS, EDIT_EMAIL_ERROR,

    // SUB
    CREATE_SUB_BEGIN, CREATE_SUB_SUCCESS, CREATE_SUB_ERROR, GET_SUBS_BEGIN, GET_SUBS_SUCCESS, CLEAR_SUB_VALUES, DELETE_SUB_BEGIN, DELETE_SUB_ERROR,

    
} from "./actions";

import axios from 'axios';

const initialState = {
    userLoading: true,
    isLoading: false,
    showAlert: false,
    alertText: '',
    alertType: '',
    user: null,
    userLocation: '',
    users: [],
    totalUsers: 0,
    showSidebar: false,
    isEditing: false,
    numOfPages: 1,
    page: 1,
    stats: {},
    statusOptions: ['subscribe', 'unsubscribe'],
    status: 'subscribe',
    monthlyApplications: [],
    search: '',
    searchStatus: 'all',
    sort: 'latest',
    sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],

    // Home Hero section
    heros: [],
    totalHeros: 0,
    herotitle: '',
    herotext: '',
    herolink: '',
    editHeroId: '',

    // blog section
    blogs: [],
    totalBlogs: 0,
    title: '',
    subtitle: '',
    author: '',
    description: '',
    fulltext: '',
    fulltexttwo: '',
    tagone: '',
    tagtwo: '',
    tagthree: '',
    tagfour: '',
    editBlogId: '',
    blogImage: [],

    // FAQ section
    faqs: [],
    totalFaqs: 0,
    faqtitle: '',
    faqtext: '',
    editFaqId: '',

    // worker section
    workers: [],
    totalWorkers: 0,
    workersname: '',
    workersposition: '',
    workerstext: '',
    editWorkerId: '',

    // Email component section
    emails: [],
    totalEmails: 0,
    emailname: '',
    emailcomponent: '',
    emailtext: '',
    editEmailId: '',
    statsEmail: {},
    statusOptionsEmail: ['unreademail', 'reademail'],
    statusEmail: 'unreademail',
    monthlyApplicationsEmail: [],
    searchEmail: '',
    searchStatusEmail: 'all',
    sortEmail: 'latest',
    sortOptionsEmail: ['latest', 'oldest', 'a-z', 'z-a'],

    // Sub component section
    subs: [],
    totalSubs: 0,
    subscribeEmail: '',
    editSubId: '',
};


const AppContext = React.createContext();

const AppProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    // Axios
    // axios.defaults.headers['Authorization'] = `Bearer ${state.token}`;
    const authFetch = axios.create({
        baseURL: 'api/v1',
    });

    // Request interceptor

    // Response interceptor
    authFetch.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            console.log(error.response);
            if (error.response.status === 401) {
                logoutUser()
            }
            return Promise.reject(error);
        }
    );

    //
    const displayAlert = () => {
        dispatch({
            type: DISPLAY_ALERT,
        });
        clearAlert();
    };

    //
    const clearAlert = () => {
        setTimeout(() => {
            dispatch({
                type: CLEAR_ALERT,
            });
        }, 3000);
    };


    //
    const setupUser = async ({ currentUser, endPoint, alertText }) => {

        // console.log(currentUser);
        dispatch({ type: SETUP_USER_BEGIN });
        try {
            const { data } = await axios.post(`/api/v1/auth/${endPoint}`, currentUser);
            const { user, location } = data;
            dispatch({
                type: SETUP_USER_SUCCESS,
                payload: { user, location, alertText },
            })
        } catch (error) {
            dispatch({
                type: SETUP_USER_ERROR,
                payload: { msg: error.response.data.msg },
            })
        }
        clearAlert();
    };


    const toggleSidebar = () => {
        dispatch({ type: TOGGLE_SIDEBAR });
    };

    const logoutUser = async () => {
        await authFetch.get('/auth/logout');
        dispatch({ type: LOGOUT_USER });
    };

    // Update a selected user
    const updateUser = async (currentUser) => {

        dispatch({ type: UPDATE_USER_BEGIN });
        try {
            const { data } = await authFetch.patch('/auth/updateUser', currentUser);

            // no token
            const { user, location } = data;

            dispatch({
                type: UPDATE_USER_SUCCESS,
                payload: { user, location },
            });
        } catch (error) {
            if (error.response.status !== 401) {
                dispatch({
                    type: UPDATE_USER_ERROR,
                    payload: { msg: error.response.data.msg },
                });
            }
        }
        clearAlert();
    };


    const handleChange = ({ name, value }) => {
        dispatch({
            type: HANDLE_CHANGE,
            payload: { name, value },
        })
    }

    const handleInputFileChange = ({ name, value }) => {
        dispatch({ type: HANDLE_FILE_CHANGE, payload: { name, value } });
    };


    // CLEAR VALUES START
    const clearHeroValues = () => {
        dispatch({ type: CLEAR_HERO_VALUES });
    };

    const clearBlogValues = () => {
        dispatch({ type: CLEAR_BLOG_VALUES });
    };

    const clearFaqValues = () => {
        dispatch({ type: CLEAR_FAQ_VALUES });
    };

    const clearWorkerValues = () => {
        dispatch({ type: CLEAR_WORKER_VALUES });
    };

    const clearValues = () => {
        dispatch({ type: CLEAR_VALUES })
    }

    const clearEmailValues = () => {
        dispatch({ type: CLEAR_EMAIL_VALUES });
    };

    const clearSubValues = () => {
        dispatch({ type: CLEAR_SUB_VALUES });
    };
    // CLEAR VALUES ENDS


    // Show all stats from the 3 different status
    const showStats = async () => {
        dispatch({ type: SHOW_STATS_BEGIN })
        try {
            const { data } = await authFetch('/sub-admin/stats')
            dispatch({
                type: SHOW_STATS_SUCCESS,
                payload: {
                    stats: data.defaultStats,
                    monthlyApplications: data.monthlyApplications,
                },
            })
        } catch (error) {
            console.log(error.response)
            logoutUser()
        }

        clearAlert()
    }

    const showStatsEmail = async () => {
        dispatch({ type: SHOW_STATSEMAIL_BEGIN })
        try {
            const { data } = await authFetch('/email-admin/statsemail')
            dispatch({
                type: SHOW_STATSEMAIL_SUCCESS,
                payload: {
                    statsEmail: data.defaultStatsEmail,
                    monthlyApplicationsEmail: data.monthlyApplicationsEmail,
                },
            })
        } catch (error) {
            console.log(error.response)
            logoutUser()
        }

        clearAlert()
    }


    // HOME HERO CRUD START

    const getHeros = async (isHeroPage) => {
        
        const { search } = state;

        let urlhero = '';

        if (isHeroPage) {
            urlhero = `/hero?isLandingHero=${isHeroPage}`;
        }
        else {
            urlhero = `/hero-admin?isLandingHero=${isHeroPage}`;

        }
        if (search) {
            urlhero = urlhero + `&search=${search}`;
        }
        dispatch({ type: GET_HEROS_BEGIN });
        try {
            const { data } = await authFetch.get(urlhero);
            const { heros, totalHeros, numOfPages } = data;
            dispatch({
                type: GET_HEROS_SUCCESS,
                payload: {
                    heros,
                    totalHeros,
                    numOfPages,
                },
            });
        } catch (error) {
            logoutUser();
        }
        // }

        clearAlert();
    };


    const setEditHero = (id) => {
        dispatch({ type: SET_EDIT_HERO, payload: { id } });
    };

    const editHero = async () => {
        dispatch({ type: EDIT_HERO_BEGIN });

        try {
            const { herotitle, herotext, herolink } = state;
            console.log("i am in edit hero");
            await authFetch.patch(`/hero-admin/${state.editHeroId}`, {
                herotitle, herotext, herolink
            });
            getHeros();
            dispatch({ type: EDIT_HERO_SUCCESS });
            dispatch({ type: CLEAR_HERO_VALUES });
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: EDIT_HERO_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };

    const deleteHero = async (HeroId) => {
        dispatch({ type: DELETE_HERO_BEGIN });
        try {
            await authFetch.delete(`/hero-admin/${HeroId}`);
            getHeros();
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: DELETE_HERO_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };
    // HOME HERO CRUD ENDS


    // BLOG CRUD BEGINS
    const createBlog = async () => {
        dispatch({ type: CREATE_BLOG_BEGIN });
        try {
            const { title, subtitle, author, description, fulltext, fulltexttwo, tagone, tagtwo, tagthree, tagfour, blogImage } = state;
            // using form data beacuse image file is uploaded
            const formData = new FormData();
            formData.append('title', title);
            formData.append('subtitle', subtitle);
            formData.append('author', author);
            formData.append('description', description);
            formData.append('fulltext', fulltext);
            formData.append('fulltexttwo', fulltexttwo);
            formData.append('tagone', tagone);
            formData.append('tagtwo', tagtwo);
            formData.append('tagthree', tagthree);
            formData.append('tagfour', tagfour);
            formData.append('blogImage', blogImage);

            await authFetch.post('/blog', formData, {});

            dispatch({ type: CREATE_BLOG_SUCCESS });
            dispatch({ type: CLEAR_BLOG_VALUES });
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: CREATE_BLOG_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };

    const getBlogs = async (isBlogPage) => {
        const { search } = state;
        let url = '';
        if (isBlogPage) {
            url = `/landing-blog?isLanding=${isBlogPage}`;

        }
        else {
            url = `/blog?isLanding=${isBlogPage}`;

        }
        if (search) {
            url = url + `&search=${search}`;
        }
        dispatch({ type: GET_BLOGS_BEGIN });
        try {
            const { data } = await authFetch.get(url);
            const { blogs, totalBlogs, numOfPages } = data;
            dispatch({
                type: GET_BLOGS_SUCCESS,
                payload: {
                    blogs,
                    totalBlogs,
                    numOfPages,
                },
            });
        } catch (error) {
            logoutUser();
        }
        // }

        clearAlert();
    };


    const setEditBlog = (id) => {
        dispatch({ type: SET_EDIT_BLOG, payload: { id } });
    };

    const editBlog = async () => {
        dispatch({ type: EDIT_BLOG_BEGIN });

        try {
            const { title, subtitle, author, description, fulltext, fulltexttwo, tagone, tagtwo, tagthree, tagfour, } = state;
            console.log("i am in edit blog");
            await authFetch.patch(`/blog/${state.editBlogId}`, {
                title,
                subtitle,
                author,
                description,
                fulltext, fulltexttwo, tagone, tagtwo, tagthree, tagfour,
            });
            getBlogs();
            dispatch({ type: EDIT_BLOG_SUCCESS });
            dispatch({ type: CLEAR_BLOG_VALUES });
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: EDIT_BLOG_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };

    const deleteBlog = async (BlogId) => {
        dispatch({ type: DELETE_BLOG_BEGIN });
        try {
            await authFetch.delete(`/blog/${BlogId}`);
            getBlogs();
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: DELETE_BLOG_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };
    // BLOG CRUD ENDS



    // Clear Filters
    const clearFilters = () => {
        dispatch({ type: CLEAR_FILTERS });
    };


    // PAGIBATION
    const changePage = (page) => {
        dispatch({ type: CHANGE_PAGE, payload: { page } })
    }


    // GET CURRENT USER COOKIES
    const getCurrentUser = async () => {
        dispatch({ type: GET_CURRENT_USER_BEGIN });
        try {
            const { data } = await authFetch('/auth/getCurrentUser');
            const { user, location } = data;

            dispatch({
                type: GET_CURRENT_USER_SUCCESS,
                payload: { user, location },
            });
        } catch (error) {
            if (error.response.status === 401) return;
            logoutUser();
        }
    };
    useEffect(() => {
        getCurrentUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    // FAQ CRUD START
    const createFaq = async () => {
        dispatch({ type: CREATE_FAQ_BEGIN });
        try {
            const { faqtitle, faqtext } = state;
            // API backend path
            await authFetch.post('/faq-admin', {
                faqtitle, faqtext
            });

            dispatch({ type: CREATE_FAQ_SUCCESS });
            dispatch({ type: CLEAR_FAQ_VALUES });
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: CREATE_FAQ_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };

    const getFaqs = async (isFaqPage) => {
        
        const { search } = state;

        let urlfaq = '';

        if (isFaqPage) {
            urlfaq = `/faq?isLandingFaq=${isFaqPage}`;
        }
        else {
            urlfaq = `/faq-admin?isLandingFaq=${isFaqPage}`;

        }
        if (search) {
            urlfaq = urlfaq + `&search=${search}`;
        }
        dispatch({ type: GET_FAQS_BEGIN });
        try {
            const { data } = await authFetch.get(urlfaq);
            const { faqs, totalFaqs, numOfPages } = data;
            dispatch({
                type: GET_FAQS_SUCCESS,
                payload: {
                    faqs,
                    totalFaqs,
                    numOfPages,
                },
            });
        } catch (error) {
            logoutUser();
        }
        // }

        clearAlert();
    };


    const setEditFaq = (id) => {
        dispatch({ type: SET_EDIT_FAQ, payload: { id } });
    };

    const editFaq = async () => {
        dispatch({ type: EDIT_FAQ_BEGIN });

        try {
            const { faqtitle, faqtext } = state;
            console.log("i am in edit faq");
            await authFetch.patch(`/faq-admin/${state.editFaqId}`, {
                faqtitle, faqtext
            });
            getFaqs();
            dispatch({ type: EDIT_FAQ_SUCCESS });
            dispatch({ type: CLEAR_FAQ_VALUES });
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: EDIT_FAQ_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };

    const deleteFaq = async (FaqId) => {
        dispatch({ type: DELETE_FAQ_BEGIN });
        try {
            await authFetch.delete(`/faq-admin/${FaqId}`);
            getFaqs();
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: DELETE_FAQ_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };
    // FAQ CRUD ENDS



    // WORKER CRUD BEGINS
    const createWorker = async () => {
        
        dispatch({ type: CREATE_WORKER_BEGIN });
        try {
            const { workersname, workersposition, workerstext, blogImage } = state;
            // using form data beacuse image file is uploaded
            const formDataworker = new FormData();
            formDataworker.append('workersname', workersname);
            formDataworker.append('workersposition', workersposition);
            formDataworker.append('workerstext', workerstext);
            formDataworker.append('blogImage', blogImage);

            await authFetch.post('/workers-admin', formDataworker, {});

            dispatch({ type: CREATE_WORKER_SUCCESS });
            dispatch({ type: CLEAR_WORKER_VALUES });
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: CREATE_WORKER_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };

    const getWorkers = async (isWorkerPage) => {
        const { search } = state;
        let url = '';
        if (isWorkerPage) {
            url = `/workers?isLanding=${isWorkerPage}`;

        }
        else {
            url = `/workers-admin?isLanding=${isWorkerPage}`;

        }
        if (search) {
            url = url + `&search=${search}`;
        }
        dispatch({ type: GET_WORKERS_BEGIN });
        try {
            const { data } = await authFetch.get(url);
            const { workers, totalWorkers, numOfPages } = data;
            dispatch({
                type: GET_WORKERS_SUCCESS,
                payload: {
                    workers,
                    totalWorkers,
                    numOfPages,
                },
            });
        } catch (error) {
            logoutUser();
        }
        // }

        clearAlert();
    };


    const setEditWorker = (id) => {
        dispatch({ type: SET_EDIT_WORKER, payload: { id } });
    };

    const editWorker = async () => {
        dispatch({ type: EDIT_WORKER_BEGIN });

        try {
            const { workersname, workersposition, workerstext, } = state;
            console.log("i am in edit worker");

            await authFetch.patch(`/workers-admin/${state.editWorkerId}`, {
                workersname, workersposition, workerstext });
            getWorkers();
            dispatch({ type: EDIT_WORKER_SUCCESS });
            dispatch({ type: CLEAR_WORKER_VALUES });
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: EDIT_WORKER_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };

    const deleteWorker = async (WorkerId) => {
        dispatch({ type: DELETE_WORKER_BEGIN });
        try {
            await authFetch.delete(`/workers-admin/${WorkerId}`);
            getWorkers();
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: DELETE_WORKER_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };
    // WORKER CRUD ENDS




     // EMAIL CRUD START
    
    const createEmail = async () => {
        dispatch({ type: CREATE_EMAIL_BEGIN });
        try {
            const { emailname, emailcomponent, emailtext } = state;
            // API backend path
            await authFetch.post('/email-admin', {
                emailname, emailcomponent, emailtext
            });

            dispatch({ type: CREATE_EMAIL_SUCCESS });
            dispatch({ type: CLEAR_EMAIL_VALUES });
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: CREATE_EMAIL_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };

    const getEmails = async (isEmailPage) => {
        
        const { page, searchEmail, searchStatusEmail, sortEmail } = state;

        let urlemail = '';

        if (isEmailPage) {
            urlemail = `/email?isLandingEmail=${isEmailPage}`;
        }
        else {
            urlemail = `/email-admin?isLandingEmail=${isEmailPage}page=${page}&statusEmail=${searchStatusEmail}&sortEmail=${sortEmail}`;

        }
        if (searchEmail) {
            urlemail = urlemail + `&searchEmail=${searchEmail}`;
        }
        dispatch({ type: GET_EMAILS_BEGIN });
        try {
            const { data } = await authFetch.get(urlemail);
            const { emails, totalEmails, numOfPages } = data;
            dispatch({
                type: GET_EMAILS_SUCCESS,
                payload: {
                    emails,
                    totalEmails,
                    numOfPages,
                },
            });
        } catch (error) {
            logoutUser();
        }
        // }

        clearAlert();
    };


    const setEditEmail = (id) => {
        dispatch({ type: SET_EDIT_EMAIL, payload: { id } });
    };

    const editEmail = async () => {
        dispatch({ type: EDIT_EMAIL_BEGIN });

        try {
            const { statusemail } = state;
            console.log("i am in edit email");
            await authFetch.patch(`/email-admin/${state.editEmailId}`, {
                statusemail
            });
            getEmails();
            dispatch({ type: EDIT_EMAIL_SUCCESS });
            dispatch({ type: CLEAR_EMAIL_VALUES });
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: EDIT_EMAIL_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };



    const deleteEmail = async (EmailId) => {
        dispatch({ type: DELETE_EMAIL_BEGIN });
        try {
            await authFetch.delete(`/email-admin/${EmailId}`);
            getEmails();
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: DELETE_EMAIL_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };

    // EMAIL CRUD ENDS



     // SUB CRUD START
    
    const createSub = async () => {
        dispatch({ type: CREATE_SUB_BEGIN });
        try {
            const { subscribeEmail, status } = state;
            // API backend path
            await authFetch.post('/sub-admin', {
                subscribeEmail, status,
            });

            dispatch({ type: CREATE_SUB_SUCCESS });
            dispatch({ type: CLEAR_SUB_VALUES });
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: CREATE_SUB_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };

    const getSubs = async (isSubPage) => {
        
        const { page, search, searchStatus, sort } = state;

        let urlsub = '';

        if (isSubPage) {
            urlsub = `/sub?isLandingSub=${isSubPage}`;
        }
        else {
            urlsub = `/sub-admin?isLandingSub=${isSubPage}page=${page}&status=${searchStatus}&sort=${sort}`;

        }
        if (search) {
            urlsub = urlsub + `&search=${search}`;
        }
        
        dispatch({ type: GET_SUBS_BEGIN });
        try {
            const { data } = await authFetch.get(urlsub);
            const { subs, totalSubs, numOfPages } = data;
            dispatch({
                type: GET_SUBS_SUCCESS,
                payload: {
                    subs,
                    totalSubs,
                    numOfPages,
                },
            });
        } catch (error) {
            logoutUser();
        }
        // }

        clearAlert();
    };


    const deleteSub = async (SubId) => {
        dispatch({ type: DELETE_SUB_BEGIN });
        try {
            await authFetch.delete(`/sub-admin/${SubId}`);
            getSubs();
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({
                type: DELETE_SUB_ERROR,
                payload: { msg: error.response.data.msg },
            });
        }
        clearAlert();
    };

    // SUB CRUD ENDS




    return (
        <AppContext.Provider
            value={{
                ...state,
                displayAlert,
                setupUser,
                toggleSidebar,
                logoutUser,
                updateUser,
                handleChange,
                handleInputFileChange,
                clearValues,
                clearHeroValues,
                getHeros,
                setEditHero,
                deleteHero,
                editHero,
                clearBlogValues,
                createBlog,
                getBlogs,
                setEditBlog,
                deleteBlog,
                editBlog,
                showStats,
                clearFilters,
                changePage,
                getCurrentUser,
                clearFaqValues,
                createFaq,
                getFaqs,
                setEditFaq,
                deleteFaq,
                editFaq,
                clearWorkerValues,
                createWorker,
                getWorkers,
                setEditWorker,
                deleteWorker,
                editWorker,
                clearEmailValues,
                createEmail,
                getEmails,
                deleteEmail,
                setEditEmail,
                editEmail,
                showStatsEmail,
                clearSubValues,
                createSub,
                getSubs,
                deleteSub,
            }} >
            {children}
        </AppContext.Provider>
    );
};


// make sure use
const useAppContext = () => {
    return useContext(AppContext);
};


export { AppProvider, initialState, useAppContext };