/* eslint-disable react/jsx-no-undef */

import React from 'react';
import './contact.css';
import { Row, Col, Form, Button, Image, Container } from 'react-bootstrap/';
import { contactinfo, contactinfoimg, contactus } from '../../utils/data';



const ContactForm = () => {

    return (
        <div>

            <div className='contact-body' style={{marginTop:20, marginBottom:20}}>
                <Row style={{ marginLeft: 0, marginRight: 0}}>
                    <Col lg={5} xl={5} style={{paddingLeft:0, paddingRight:0, }}>
                        {contactinfo.map((item, index) => (
                            <React.Fragment key={index}>
                                <div style={{marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <h3 className='text-center'>{item.title}</h3>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                        
                        <div style={{ marginTop: 20,}}>
                            <div>
                                <div lg={8} xl={8} style={{}}>
                                    <div className='service-box px-3 text-black' style={{borderRadius: 15, fontWeight:'bolder'}}>
                                        <Container>
                                            <Row className='justify-content-md-center'>
                                                {contactinfo.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        <Col lg={5} xl={5}>
                                                            <p style={{textAlign:'center', marginBottom:'-6px', textDecoration:'underline', color:'#3C9FE1'}}>{item.mailtitle}</p>
                                                            <p style={{textAlign:'center',}}>{item.mail}</p>
                                                        </Col>
                                                        <Col lg={5} xl={5}>
                                                            <p style={{textAlign:'center', marginBottom:'-6px', textDecoration:'underline', color:'#3C9FE1'}}>{item.phonetitle}</p>
                                                            <p style={{textAlign:'center',}}>{item.phone}</p>
                                                        </Col>
                                                    </React.Fragment>
                                                ))}
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                            </div>
                            <Container>
                                <Row className='mx-auto px-2 mt-2'>
                                    {contactinfoimg.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <Col>  
                                                <Container>
                                                    <div className='contact-img'>
                                                        <Image src={item} rounded  />
                                                    </div>
                                                </Container>
                                            </Col>
                                        </React.Fragment>
                                    ))}
                                </Row>
                            </Container>
                        </div>
                    </Col>

                    <Col lg={7} xl={7} style={{paddingLeft:0, paddingRight:0}}> 
                        <div className='form-body' style={{maxWidth: '800px', margin:'auto', marginBottom:20}}>
                            <Form className='mx-2'>
                                <Row style={{ marginLeft: 0, marginRight: 0}}>
                                    {contactus.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <div style={{marginTop: 20, marginBottom:10}}>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <icon className="fs-3" style={{marginRight:3, marginTop:'-10px'}} >{item.contacticon}</icon>
                                                    <h3>{item.contacttitle}</h3>
                                                </div>
                                                <p className='fs-6 fw-lighter'>{item.contacttext}</p>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                    <Col>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                            <Form.Label style={{fontWeight:'bold'}}>First name</Form.Label>
                                            <Form.Control type='first name' placeholder="First name" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                        <Form.Label style={{fontWeight:'bold'}}>Last Name</Form.Label>
                                        <Form.Control type='last name' placeholder="Last name" />
                                    </Form.Group>
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: 0, marginRight: 0}}>
                                    <Col>
                                        <div style={{maxWidth:'380px'}}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                                <Form.Label style={{fontWeight:'bold'}}>Email address</Form.Label>
                                                <Form.Control type="email" placeholder="mail@example.com" />
                                            </Form.Group>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: 0, marginRight: 0}}>
                                    <Col>
                                        <div style={{maxWidth:'380px'}}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label style={{fontWeight:'bold'}}>Enter a message</Form.Label>
                                                <Form.Control as="textarea" placeholder="Enter a message or ask a question" rows={3} />
                                            </Form.Group>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: 0, marginRight: 0}}>
                                    <Col>
                                        <Button variant="primary" type="submit" style={{display: 'flex', alignItems: 'center', marginBottom: 20}}>
                                            send
                                        </Button>
                                        
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
            
    );

};

export default ContactForm;
