/* eslint-disable jsx-a11y/anchor-is-valid */

import { IoBarChartSharp } from 'react-icons/io5';
import { MdOutlineEmail } from 'react-icons/md';
import { ImProfile } from 'react-icons/im';
import { FaBlogger, FaQuora } from 'react-icons/fa';
import { GiMaterialsScience, GiNinjaHeroicStance, GiWorld } from 'react-icons/gi';
import { HiOutlineNewspaper } from 'react-icons/hi';

// path needs to be the same as in the app.js
const links = [
    {
        id: 0,
        text: '- Overview -'
    },
    {
        id: 1,
        text: 'stats',
        path: '/',
        icon: <IoBarChartSharp />,
    },
    {
        id: 2, 
        text: 'geography', 
        path: 'geography', 
        icon: <GiWorld />,
    },
    {
        id: 3,
        text: '- Client Section -'
    },
    {
        id: 4,
        text: 'hero',
        path: 'heroadmin',
        icon: <GiNinjaHeroicStance/>,
    },
    {
        id: 5,
        text: 'blogs',
        path: 'blogs',
        icon: <FaBlogger />,
    },
    {
        id: 6,
        text: 'faq',
        path: 'faqadmin',
        icon: <FaQuora />,
    },
    {
        id: 7,
        text: 'workers',
        path: 'workers',
        icon: <GiMaterialsScience />,
    },
    {
        id: 8,
        text: '- Admin Section -'
    },
    {
        id: 9,
        text: 'User',
        path: 'profile',
        icon: <ImProfile />,
    },
    {
        id: 10,
        text: 'email',
        path: 'emailadmin',
        icon: <MdOutlineEmail />
    },
    {
        id: 11,
        text: 'sub',
        path: 'subadmin',
        icon: <HiOutlineNewspaper />
    },
];

export default links;