
import * as React from 'react';
import moment from 'moment';
import { useAppContext } from '../context/appContext';
import Table from 'react-bootstrap/Table';
import '../styles/Home.css';

const Sub = ({ _id, subscribeEmail, createdAt, status, isSubPage }) => {

    const { deleteSub } = useAppContext()

    let date = moment(createdAt)
    date = date.format('MMM Do, YYYY');

    let time = moment(createdAt)
    time = time.format("HH:mm:ss");

    
    return (

        <div className='' style={{ paddingBottom: 10, paddingTop:10, }}>
            <Table responsive>
                <thead>
                    <tr>
                    <th>Titles:</th>
                    <th>Email Adress</th>
                    <th>Status</th>
                    <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>Info:</td>
                    <td>{subscribeEmail}</td>
                    <td>{status}</td>
                    <td>{date} -- {time}</td>
                    </tr>
                </tbody>
                { isSubPage ? (<>
                    </>) : <>
                        <footer>
                            <div className='actions'>
                                <button
                                    type='button'
                                    className='btn delete-btn btn-danger mx-2'
                                    onClick={() => deleteSub(_id)}
                                >
                                    Delete
                                </button>
                            </div>
                        </footer>
                    </>}
            </Table>
        </div>

    );
};

export default Sub;