
import React, { useState } from 'react';
import BarChartEmail from './BarChartEmail';
import AreaChartEmail from './AreaChartEmail';
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/ChartsContainer';

export default function ChartsContainerEmail() {

    const [barChartEmail, setBarChartEmail] = useState(true);
    const { monthlyApplicationsEmail: data } = useAppContext();

    return (
        <Wrapper>
            <h4>Monthly Emails</h4>
            <h5>Choose between Area and Bar chart</h5>
            <hr></hr>
            <button type='button' onClick={() => setBarChartEmail(!barChartEmail)}>
                {barChartEmail ? 'AreaChart' : 'BarChart'}
            </button>
            {barChartEmail ? <BarChartEmail data={data} /> : <AreaChartEmail data={data} />}
        </Wrapper>
    );
}


