

import ReactGA from 'react-ga4';

export const AnalyticEvent = (category, action, label) => {

    ReactGA.event({
        category: category && 'Button',
        action: action && 'Click',
        label: label && 'Subscribe' && 'Submit' && 'submit' && 'Button',
    });
};

