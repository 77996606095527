
import React from 'react'
import BlogsContainer from '../components/BlogsContainer';
import { Container } from 'react-bootstrap';


const WebBlog = () => {

    return (
        <div className='blog'>
            <React.Fragment>
                <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ minHeight: '800px' }}>
                        <h2 className='text-center text-light pt-4' style={{fontFamily: 'Montserrat'}}>Blogs</h2>
                        {/* Only for frontend */}
                        <BlogsContainer isBlogPage={true} />
                    </div>
                </Container>
            </React.Fragment>
        </div>
    );
}


export default WebBlog