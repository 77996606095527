
import React from 'react';
import '../../styles/About.css';
import { Col, Container, Row } from 'react-bootstrap';
import { FaArrowAltCircleRight } from "react-icons/fa";
import AboutMedia from './AboutMedia';




const AboutUs = () => {


    return (
        <div className='hvem-sektion my-4' id="hvem">
            <Row style={{ marginLeft:0, marginRight:0, }}>
                <Col sm={12} md={12} lg={12} xl={12} style={{ paddingLeft:0, paddingRight:0, }}>
                    <div className='hvem-sektion-h2 text-center'>
                        <h2 style={{fontFamily: 'Montserrat'}}>Who are we?</h2>
                        <h5 className='text-secondary' style={{marginTop:20, fontFamily: 'Montserrat'}}>The people behind Nanoscale Simulations</h5>
                        <hr></hr>
                    </div>
                </Col>
            </Row>

            <Container>
                <Row>
                    <Col sm={12} md={3} lg={3} xl={3} >
                        <div className="person">
                            <div className="container-person">
                                <div className="container-inner">
                                    <img className="img img1" alt="view" src={require('../../img/Zan.jpg')} />
                                </div>
                            </div>
                            <div className="divider-person"></div>
                            <div className="name-person">Zan Mahmood</div>
                            <div className="title-person">Founder of NanoScale Simulations</div>
                                {/* SOCAIL MEDIA SECTION STARTS */}

                                <AboutMedia />

                                {/* SOCAIL MEDIA SECTION STARTS */}
                        </div>
                    </Col>
                    <Col sm={12} md={9} lg={9} xl={9}>
                        <div className='hvem-content'>

                            <div className='hvem-rolle p-2'>
                                <h3 className='my-role'>[ My role ]</h3>
                                <div className="hvem-box shadow-lg" style={{fontFamily: 'Obito', backgroundColor: '#e5e4e2', padding: '10px', borderRadius: '15px'}}>
                                    <div>
                                        <FaArrowAltCircleRight size={25} className='d-inline mx-2 d-none d-md-block' style={{float: 'left', color:'#3C9FE1'}}/>
                                        <p className='d-inline-block fs-6' style={{paddingTop:'0px'}}>
                                            My name is Zan Mahmood, I have studied Physics and Nanotechnology at the Technical University of
                                            Denmark (DTU) and I founded Nanoscale Simulations in 2020.
                                        </p>
                                    </div>
                                    <div>
                                        <FaArrowAltCircleRight size={25} className='d-inline mx-2 d-none d-md-block' style={{float: 'left', color:'#3C9FE1'}}/>
                                        <p className='d-inline-block fs-6' style={{paddingTop:'0px'}}>
                                            I have a passion for building software and solutions for simulating physical and chemical phenomena at nanoscale.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='hvem-historie p-2'>
                                <h3 className='my-role'>[ My Story ]</h3>
                                <div className="hvem-box shadow-lg" style={{fontFamily: 'Obito', backgroundColor: '#e5e4e2', padding: '10px', borderRadius: '15px'}}>
                                    <div>
                                        <FaArrowAltCircleRight size={25} className='d-inline mx-2 d-none d-md-block' style={{float: 'left', color:'#3C9FE1'}}/>
                                        <p className='d-inline-block fs-6' style={{paddingTop:'0px'}}>
                                        During my studies I came across a lot of struggles when it came to simulating atoms and their behaviour.
                                        I started to realise, that even though there is so much research and development being done in the 
                                        field of Computational Chemistry.<br/> 
                                        There is not a lot of effort being put into building tools with intuitive and good quality user experience. 
                                        </p>
                                    </div>
                                    <div>
                                        <FaArrowAltCircleRight size={25} className='d-inline mx-2 d-none d-md-block' style={{float: 'left', color:'#3C9FE1'}}/>
                                        <p className='d-inline-block fs-6' style={{paddingTop:'0px'}}>
                                            So I set out to build these tools myself to empower not Only researchers but also next generation of researchers and engineers.<br/> 
                                            I believe that by building tools that makes it easier to simulate and understand the results, we can focus more on the research and work at hand.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Col>
                </Row>

                {/*
                <hr></hr>
                
                <Row>
                    <Col xs={{span: 12, order: 2}} md={{span: 9, order: 2}} lg={{span: 9, order: 1,}} xl={{span: 9, order:1}}>
                        <div className='hvem-content-right'>
                            <div className='hvem-rolle-right'>
                                <h4 style={{fontFamily: 'Montserrat'}}>Min Rolle </h4>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            <div className='hvem-historie-right'>
                                <h4 style={{fontFamily: 'Montserrat'}}>Min Historie</h4>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                    </Col>
                    <Col xs={{span: 12, order: 1}} md={{span: 3, order: 2}} lg={{span: 3, order: 2}} xl={{span: 3, order:2}} >
                        <div className="person">
                            <div className="container-person">
                                <div className="container-inner">
                                    <img className="img img2" alt="view" src={require('../../img/ar-glasses.png')} />
                                </div>
                            </div>
                            <div className="divider-person"></div>
                            <div className="name-person">Partner Doe</div>
                            <div className="title-person">CEO of NanoScale</div>
                        </div>
                    </Col>
                </Row>
                */}
                <hr></hr>

            </Container>
        </div>
    )

};


export default AboutUs;