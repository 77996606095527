
import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import '../../styles/Home.css';

const ValueCard = () => {

    return (
        <Row style={{ marginLeft: 0, marginRight: 0}} >
        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <div className="blog-card blog-card-blog" style={{ minHeight: '400px', maxWidth: '400px'}}>
                <div className="blog-card-image">
                    <a href="/"> <Image className="img" src={require('../../img/learn.jpeg')} style={{width:400,height:210}} alt=''/> </a>
                    <div className="ripple-cont"></div>
                </div>
                <h6 className="blog-category blog-text-success" style={{marginLeft:'25px'}}><i className="far fa-newspaper"></i> Run simulations without any experience</h6>
                <div className="blog-table bg-light">
                    <h3 className="blog-card-caption" style={{color:'black', fontFamily: 'roboto'}}>
                        Overcome learning barriers
                    </h3>
                    <hr></hr>
                    <p className="blog-card-description" style={{minHeight: '150px', fontFamily: 'roboto', lineHeight: '40px', fontSize: '18px', fontWeight: 'bold'}}>
                        <li>Understand abstract concepts</li>
                        <li>Hands-on problem-solving</li>
                        <li>Explore science at first-hand</li>
                    </p>
                </div>
            </div>
        </Col>

        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <div className="blog-card blog-card-blog" style={{ minHeight: '400px', maxWidth: '400px'}}>
                <div className="blog-card-image">
                    <a href="/"> <Image className="img" src={require('../../img/students.JPEG')} style={{width:400,height:210}} alt=''/> </a>
                <div className="ripple-cont"></div>
                </div>
                <h6 className="blog-category blog-text-success" style={{marginLeft:'25px'}}><i className="far fa-newspaper"></i> Learn science with atomic simulations</h6>
                <div className="blog-table bg-light">
                    <h3 className="blog-card-caption" style={{color:'black', fontFamily: 'roboto'}}>
                        Improve student performance
                    </h3>
                    <hr></hr>
                    <p className="blog-card-description" style={{minHeight: '150px', fontFamily: 'roboto', lineHeight: '40px', fontSize: '18px', fontWeight: 'bold'}}>
                        <li>Increase student engagement</li>
                        <li>Make science learning interactive</li>
                        <li>Learn by exploration and play</li>
                    </p>
                </div>
            </div>
        </Col>

        <Col xs={12} sm={12} md={12} lg={4} xl={4} >
            <div className="blog-card blog-card-blog" style={{ minHeight: '400px', maxWidth: '400px'}}>
                <div className="blog-card-image">
                    <a href="/"> <Image className="img" src={require('../../img/NanoCraft1.PNG')} style={{width:400, height:210}} alt=''/> </a>
                <div className="ripple-cont"></div>
                </div>
                <h6 className="blog-category blog-text-success" style={{marginLeft:'25px'}}><i className="far fa-newspaper"></i> Study complex phenomena at nanoscale</h6>
                <div className="blog-table bg-light">
                    <h3 className="blog-card-caption" style={{color:'black', fontFamily: 'roboto'}}>
                        Develop competencies & skills
                    </h3>
                    <hr></hr>
                    <p className="blog-card-description" style={{minHeight: '150px', fontFamily: 'roboto', lineHeight: '40px', fontSize: '18px', fontWeight: 'bold'}}>
                        <li>Develop critical thinking</li>
                        <li>Learn scientific problem-solving</li>
                        <li>Stimulate creativite thinking</li>
                    </p>
                </div>
            </div>
        </Col>
    </Row>
    );
};

export default ValueCard;