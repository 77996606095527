
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
// import Stack from 'react-bootstrap/Stack';
import '../../styles/Home.css';
import '../../styles/CTA.css';


const UserCase = () => {

    return (
        <div className="usercase my-4">
            <Container className="py-2">
                <div className="usercase-wrapper1 mb-4">
                    <Card className="shadow-lg" style={{borderRadius:20}}>
                        <Row >
                            <Col xs={{span: 12, order: 2}} md={{span: 12, order: 2}} lg={{span: 4, order: 1, offset: 0}}>
                                <div className="usercase-info p-4">
                                    <h4 className="blog-card-caption" style={{color:'black', fontFamily: 'roboto'}}>NanoCraft VR</h4>
                                    <hr></hr>
                                    <h5 style={{fontFamily: 'Roboto', lineHeight: '1.2', paddingBottom: '6px', fontWeight: 'bold'}}>Explore atomic science in VR</h5>
                                    <p style={{fontFamily: 'Roboto', fontSize: '16px', lineHeight: '1.6'}}>
                                    NanoCraft VR is a learning tool for learning and exploring the abstract world of atoms.
                                    By using simulations as models, students will gain a better understanding of what atoms are and how they behave.
                                    Students will develop skills and competencies to better create create mental models and intuition which will
                                    benefit them in understanding natural sciences.
                                    </p>
                                    <hr></hr>
                                    <div className='usercase-body-stack'>
                                        {/* <Stack className='usercase-stack text-center' direction="horizontal" gap={2} style={{display: 'flex', justifyContent: 'start', alignItems: 'start'}}>
                                            <div className="p-2 bg-primary rounded-pill" style={{color:'white'}}>Calculator</div>
                                            <div className="p-2 bg-secondary rounded-pill" style={{color:'white'}}>Web App</div>
                                            <div className="p-2 bg-primary rounded-pill" style={{color:'white'}}>Research</div>
                                        </Stack> */}
                                        <Container style={{display: 'flex', justifyContent: 'start', alignItems: 'start', paddingLeft: '0px'}}>
                                        <div className='value-more-button pt-4 pb-2'>
                                            <Button href="/Products" className='hero-btn-body'>Learn More</Button>
                                        </div>
                                        </Container>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{span: 12, order: 1}} md={{span: 6, order: 1, offset:3}} lg={{span: 8, order: 2, offset: 0}}>
                                <Image className="usercase-img p-4" src={require('../../img/bg_container1.png')} alt="llalal" style={{maxHeight:500}}></Image>
                            </Col>
                        </Row>
                    </Card>  
                </div>
            </Container>
        </div>
        
    );

}

export default UserCase;
