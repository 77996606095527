
import React from 'react'
import { FormRow, Alert } from '../../components'
import { useAppContext } from '../../context/appContext'
import Wrapper from '../../assets/wrappers/DashboardFormPage'
import HerosContainer from '../../components/HerosContainer'
import TextAreaRow from '../../components/TextAreaRow'



const HeroAdmin = () => {
    const { isLoading, isEditing, showAlert, displayAlert, herotitle, herotext, herolink, handleChange, clearHeroValues, editHero } = useAppContext();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!herotitle || !herotext || !herolink ) {
            displayAlert()
            return
        }
        if (isEditing) {
            editHero()
            return
        }
    }

    const handleHeroInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        handleChange({ name, value })
    }


    return (
        <Wrapper>
            <form className='form'>
                <h3>{isEditing}</h3>
                {showAlert && <Alert />}
                <div className='form-center'>
                    {/* title */}
                    <TextAreaRow
                        placeholder='Hero title'
                        type='text'
                        name='herotitle'
                        value={herotitle}
                        handleChange={handleHeroInput}
                    />
                    {/* text */}
                    <TextAreaRow
                        placeholder='Hero text'
                        type='text'
                        name='herotext'
                        value={herotext}
                        handleChange={handleHeroInput}
                    />
                    {/* hero article link*/}
                    <FormRow
                        placeholder='Hero article link'
                        type='text'
                        name='herolink'
                        value={herolink}
                        handleChange={handleHeroInput}
                    />
                    {/* btn container */}
                    <div className='btn-container'>
                        <button type='submit' className='btn btn-block submit-btn' onClick={handleSubmit} disabled={isLoading} >
                            submit
                        </button>
                        <button className='btn btn-block clear-btn' onClick={(e) => {
                            e.preventDefault() 
                            clearHeroValues()
                            }} 
                        >
                            clear
                        </button>
                    </div>
                </div>
            </form>
            <hr></hr>
            {/* False for backend display */}
            <HerosContainer isHeroPage={false} />
        </Wrapper>


    )
}

export default HeroAdmin;