
import React from 'react';
import '../styles/Home.css';
import "../styles/Email.css";
import { Col, Row, Container, Modal } from 'react-bootstrap';
import ModalEmail from '../components/CTA/ModalEmail';
import Subscriber from '../components/CTA/Subscriber';
import UserCase from '../components/Forside/userCase';
import UserCaseRight from '../components/Forside/userCaseRight';
import FaqsContainer from '../components/FaqsContainer';
import HerosContainer from '../components/HerosContainer';
import ScrollButton from '../components/CTA/ScrollButton';
import ValueCard from '../components/Forside/ValueCard';
import HeroVideo from '../components/produkter/produktVideo';


function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Video
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>
                Coming soon
            </p>
            <figure>
                <HeroVideo/>
            </figure>
            </Modal.Body>
        </Modal>
        );
};

function MyVerticallyCenteredModalMiddle(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Video
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>
                Coming soon
            </p>
            <figure>
                <HeroVideo/>
            </figure>
            </Modal.Body>
        </Modal>
        );
};


function MyVerticallyCenteredModalRight(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Video
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>
                Coming soon
            </p>
            <figure>
                <HeroVideo/>
            </figure>
            </Modal.Body>
        </Modal>
        );
};



function Home() {

    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowMiddle, setModalShowMiddle] = React.useState(false);
    const [modalShowRight, setModalShowRight] = React.useState(false);

    return (
        <div className="Home">
            <Container fluid style={{paddingLeft:0, paddingRight:0}}>

                {/*HERO SECTION STARTS*/}

                <React.Fragment>
                    {/* FRONTEND API */}
                    <HerosContainer isHeroPage={true} />
                </React.Fragment>

                {/*HERO SECTION ENDS*/}



                {/* VÆRDICARD / VALUECARD SECTION STARTS */}

                <div className='værdi-wrapper py-2'>

                    <Row style={{ marginLeft: 0, marginRight: 0}}> 
                        <Col sm={12} md={12} lg={12} style={{paddingLeft:0, paddingRight:0}}>
                            <div  className='værdi-text text-center my-2'>
                                <h2 style={{fontFamily: 'Roboto', fontWeight: 'bold'}}>Explore atomic science in virtual reality</h2>
                                <h5 className='text-secondary' style={{fontFamily: 'Roboto'}}>Our simulations deliver hands-on interaction and engaging experiences</h5>
                            </div>
                        </Col>
                    </Row>

                    <Container className='pt-4'>
                        <ValueCard />
                    </Container>
                </div>    
                  {/* KNOW MORE SECTION */}
                <div className='know-more-wrapper'>
                    <Container style={{paddingLeft:0, paddingRight:0, paddingTop: '50px'}}>
                        <div  className='værdi-text text-center my-2'>
                            <h2 style={{fontFamily: 'Roboto', fontWeight: 'bold'}}>Explore the world of atoms</h2>
                            <h5 className='text-secondary' style={{fontFamily: 'Roboto', paddingBottom: '50px'}}>Enter a world where atoms become tangible and interactive</h5>
                        </div>
                        <Row style={{ marginLeft: 0, marginRight: 0}}>
                            <Col sm={12} md={12} lg={4} >
                                <div className="card-know-more card-1" onClick={() => setModalShow(true)}>
                                    {/* <span className='bg-info px-2 py-2 rounded fs-6 text-white'>Blog <FaLongArrowAltRight size={18} className='icon-shake' /></span> */}
                                    <h3 className='mt-3'>Build atomic systems</h3>
                                    <p>Enter a virtual lab filled with tools for building molecules and materials atom by atom.</p>
                                </div>
                                <MyVerticallyCenteredModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                />
                            </Col>
                            <Col sm={12} md={12} lg={4}>
                                <div className="card-know-more card-2" onClick={() => setModalShowMiddle(true)}>
                                    {/* <span className='bg-info px-2 py-2 rounded fs-6 text-white'>Blog <FaLongArrowAltRight size={18} className='icon-shake' /></span> */}
                                    <h3 className='mt-3'>Investigate properties</h3>
                                    <p>Access advanced simulations with a user-friendly interfaces to investigate the properties of your molecule.</p>
                                </div>
                                <MyVerticallyCenteredModalMiddle
                                    show={modalShowMiddle}
                                    onHide={() => setModalShowMiddle(false)}
                                />
                            </Col>
                            <Col sm={12} md={12} lg={4}>
                                <div className="card-know-more card-3" onClick={() => setModalShowRight(true)}>
                                    {/* <span className='bg-info px-2 py-2 rounded fs-6 text-white'>Blog <FaLongArrowAltRight size={18} className='icon-shake' /></span> */}
                                    <h3 className='mt-3'>Explore and learn</h3>
                                    <p>With non-linear simulations, you have the freedom to explore and be creative, while learning.</p>
                                </div>
                                <MyVerticallyCenteredModalRight
                                    show={modalShowRight}
                                    onHide={() => setModalShowRight(false)}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>


                {/* VÆRDICARD / VALUECARD SECTION ENDS */}





                {/* USER CASES SECTION STARTS */}

                <div className='usercase-wrapper py-4'>
                    <Row style={{marginLeft: 0, marginRight: 0}}>
                        <Col sm={12} md={12} lg={12} style={{paddingLeft:0, paddingRight:0}}>
                            <div className='usercase-title text-center my-3 py-3'>
                            <h2 style={{fontFamily: 'Roboto', fontWeight: 'bold'}}>Our products and services</h2>
                            <h5 className='text-secondary' style={{fontFamily: 'Roboto'}}>Read About Our No-Code Tools For Easily Simulating Atomic Systems</h5>
                            </div>
                            <div className='usercase-komponent'>
                                <Container>
                                    <UserCase/>
                                </Container>
                                <Container>
                                    <UserCaseRight/> 
                                </Container>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* USER CASES SECTION ENDS */}



                {/* FAQ SECTION STARTS */}
                <div className='text-dark py-4 my-4'>
                    <Row className='justify-content-center' style={{marginLeft: 0, marginRight: 0}}>
                        <Col sm={12} md={8} lg={8} xl={8} style={{paddingLeft:0, paddingRight:0}}>
                            <div className='FAQ-komponent mb-4'>
                                <div className='faq'>
                                    <React.Fragment>
                                        <Container style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{maxWidth:1000, width:1000}}>
                                                <h2 className='text-center text-dark pt-4' style={{fontFamily: 'Montserrat'}}>FAQ's</h2>
                                                <hr></hr>
                                                {/* For frontend diplay */}
                                                <FaqsContainer isFaqPage={true} />
                                            </div>
                                        </Container>
                                    </React.Fragment>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                 {/* FAQ SECTION ENDS */}
                
                

                {/* SUBSCRIBER SECTION STARTS */}
                <div className='text-dark'>
                    <Row className='justify-content-center' style={{marginLeft: 0, marginRight: 0}}>
                        <Col sm={12} md={10} lg={8} xl={8} style={{paddingLeft:0, paddingRight:0}}>
                            <div className='subscriber-komponent px-4'>
                                <div className="hero" style={{maxHeight:600, minHeight:350}}> 
                                    <div className="hero__title">
                                        <div className='text-center text-light' style={{marginTop: '20px'}}>
                                            <h2 className='my-2 py-2' style={{fontFamily: 'Montserrat'}}>Want to know more?</h2>
                                        </div>
                                        <Subscriber/>
                                    </div>
                                    <div className="cube"></div>
                                    <div className="cube"></div>
                                    <div className="cube"></div>
                                    <div className="cube"></div>
                                    <div className="cube"></div>
                                    <div className="cube"></div>
                                </div>
                            </div>
                            
                        </Col>
                    </Row>
                </div>
                 {/* SUBSCRIBER SECTION ENDS */}


                {/* GLOBAL COMPONENTS USAGE STARTS */}
                <ModalEmail />
                <ScrollButton />
                {/* GLOBAL COMPONENTS USAGE ENDS */}

            </Container>
        </div>
    );
};

export default Home;