
import { useState } from 'react';
import { FormRow, Alert } from '../../components';
import { useAppContext } from '../../context/appContext';
import Wrapper from '../../assets/wrappers/DashboardFormPage';


const initialState = {
    name: '',
    email: '',
    password: '',
};

const Profile = () => {

    const { user, showAlert, displayAlert, updateUser, isLoading, setupUser } = useAppContext();
    const [name, setName] = useState(user?.name);
    const [email, setEmail] = useState(user?.email);
    const [lastName, setLastName] = useState(user?.lastName);
    const [location, setLocation] = useState(user?.location);

    const [values, setValues] = useState(initialState); // Local values



    const handleSubmit = (e) => {
        e.preventDefault();
        if (!name || !email || !lastName || !location) {
        displayAlert();
        return;
        }
        updateUser({ name, email, lastName, location });
    };


    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { name, email, password, isMember } = values;
        if (!email || !password || (!isMember && !name)) {
            displayAlert();
            return;
        }
        const currentUser = {name, email, password}
        if(!isMember) {
            setupUser({
                currentUser, 
                endPoint:'register', 
                alertText: 'User created! Redirecting...'
            }) 
        }
        else  {
            // console.log('Already a member!');
            setupUser({
            }) // set as an object
        }
    };


    return (

        <div className=''>
            <Wrapper>
                <form className='form' onSubmit={handleSubmit}>
                    <h3>profile </h3>
                    {showAlert && <Alert />}

                    {/* name */}
                    <div className='form-center'>
                    <FormRow
                        placeholder='First name'
                        type='text'
                        name='name'
                        value={name}
                        handleChange={(e) => setName(e.target.value)}
                    />
                    <FormRow
                        placeholder='Last name'
                        labelText='last name'
                        type='text'
                        name='lastName'
                        value={lastName}
                        handleChange={(e) => setLastName(e.target.value)}
                    />
                    <FormRow
                        placeholder='Email'
                        type='email'
                        name='email'
                        value={email}
                        handleChange={(e) => setEmail(e.target.value)}
                    />

                    <FormRow
                        placeholder='Location'
                        type='text'
                        name='location'
                        value={location}
                        handleChange={(e) => setLocation(e.target.value)}
                    />
                    <button className='btn btn-block' type='submit' disabled={isLoading}>
                        {isLoading ? 'Please Wait...' : 'save changes'}
                    </button>
                    </div>
                </form>
            </Wrapper>

            <hr></hr>

            <form className='form' onSubmit={onSubmit}>
                {showAlert && <Alert />}
                <h4 className='text-center pb-2 mb-2'>Register a User</h4>
                {/* name field */}
                <div className="formrow-email" style={{marginBottom:'10px'}}>
                    {!values.isMember && (
                        <FormRow
                            placeholder='Your name'
                            type='text'
                            name='name'
                            value={values.name}
                            handleChange={handleChange}
                        />
                    )}
                </div>

                {/* email input */}
                <div className="formrow-email" style={{marginBottom:'10px'}}>
                    <FormRow
                    placeholder='Your email'
                    type='email'
                    name='email'
                    value={values.email}
                    handleChange={handleChange}
                    />
                </div>

                {/* password input */}
                <div className="formrow-email" style={{marginBottom:'10px'}}>
                <FormRow
                placeholder='Your password'
                    type='password'
                    name='password'
                    value={values.password}
                    handleChange={handleChange}
                />
                </div>

                <button type='submit' className='btn btn-block' disabled={isLoading}>
                    Submit
                </button>
            </form>
        </div>

    );
};

export default Profile;