
import * as React from 'react';
import moment from 'moment';
import { useAppContext } from '../context/appContext';
import Table from 'react-bootstrap/Table';
import '../styles/Email.css';
import { Link } from 'react-router-dom';


const Email = ({ _id, emailname, emailcomponent ,emailtext, statusemail, createdAt, isEmailPage }) => {

    const { setEditEmail, deleteEmail } = useAppContext()

    let date = moment(createdAt)
    date = date.format('MMM Do, YYYY');

    let time = moment(createdAt)
    time = time.format("HH:mm:ss");
    

    return (

        <div className='' style={{ paddingBottom: 10, paddingTop:10, }}>
            { isEmailPage ? (<>
                </>) : <>
                <Link to='/emailadmin' className='edit-btn' onClick={() => setEditEmail(_id)} style={{textDecoration: 'none'}}>
                    <Table responsive>
                        <thead>
                            <tr>
                            <th><h5>Status</h5></th>
                            <th><h5>Full Name</h5></th>
                            <th><h5>Email Adress</h5></th>
                            <th><h5>Text</h5></th>
                            <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td><span className='text-danger' style={{fontWeight: 'bold'}}>{statusemail}</span></td>
                            <td>{emailname}</td>
                            <td>{emailcomponent}</td>
                            <td>{emailtext}</td>
                            <td>{date} -- {time}</td>
                            </tr>
                        </tbody>
                        <footer>
                            <div className='actions'>
                                <button type='button' className='btn delete-btn btn-danger mx-2 mt-2' onClick={() => deleteEmail(_id)} >
                                    Delete
                                </button>
                            </div>
                        </footer>
                    </Table>
                </Link>
            </>}
        </div>

    );
};

export default Email;