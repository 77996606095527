
import { useAppContext } from '../context/appContext';
import { useEffect, useState } from 'react';
import Loading from './Loading';
import Alert from './Alert';
import Wrapper from '../assets/wrappers/WorkersContainer';
import PageBtnContainer from './PageBtnContainer';
import Worker from './Worker';
import axios from 'axios';



const WorkersContainer = (props) => {

     // totalBlogs, numOfPages can be insert for later use
    const { getWorkers, isLoading, page, search, searchStatus, searchType, sort, showAlert } = useAppContext();

    const [workerss, setWorkerss] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [totalWorkerss, setTotalWorkerss] = useState(0);
    const [numOfPagess, setNumOfPagess] = useState(0);

    useEffect(() => {
        getWorkers(props.isWorkerPage);
        getWorkersApi(props.isWorkerPage);
        // eslint-disable-next-line
    }, [page, search, searchStatus, searchType, sort, props.isWorkerPage]);
    


    async function getWorkersApi(isWorkerPage) {
    
        let url = '';
        if (isWorkerPage) {
            // Frontend API
            url = `/workers?isLanding=${isWorkerPage}`;
        }
        else {
            // Backend API
            url = `/workers-admin?isLanding=${isWorkerPage}`;
        }
        try {
            const authFetch = axios.create({
                baseURL: 'api/v1',
            });
            // Response interceptor
            authFetch.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    console.log(error.response);
                    if (error.response.status === 401) {
                    }
                    return Promise.reject(error);
                }
            );

            const { data } = await authFetch.get(url);
            const { workers, totalWorkers, numOfPages } = data;

            setWorkerss(workers);
            setTotalWorkerss(totalWorkers);
            setNumOfPagess(numOfPages);
        } catch (error) {
            console.log(error.message);
        }
    }


    if (isLoading) {
        return <Loading center />;
    }

    if (!workerss || workerss.length === 0) {
        return (
            <>
                <h2>No workers to display...</h2>
            </>
        );
    }

    return (
        <Wrapper>
            {showAlert && <Alert />}

            {/*
            <h5 className='text-center' style={{color:'#DFDFDF', backgroundColor:'#3766C4', maxWidth:280, padding:8, borderRadius:20}}>
            We have {totalWorkerss} {workerss.length > 1 && 'members'}
            </h5>
             */}
            <div className='jobs mt-2 pt-2'>
                {workerss.map((worker) => {
                    return <Worker key={worker._id} {...worker} isWorkerPage={props.isWorkerPage} />;
                })}
            </div>
            {numOfPagess > 1 && <PageBtnContainer />}
        </Wrapper>
    );
};

export default WorkersContainer;