
import { useAppContext } from '../context/appContext';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import Wrapper from '../assets/wrappers/PageBtnContainer';



const PageBtnContainer = () => {

    const { numOfPages, page, changePage } = useAppContext()

    const pages = Array.from({ length: numOfPages }, (_, index) => {
        return index + 1
    })
    const nextPage = () => {
        let newPage = page + 1
        if (newPage > numOfPages) {
        newPage = 1
        }
        changePage(newPage)
    }
    const prevPage = () => {
        let newPage = page - 1
        if (newPage < 1) {
        newPage = numOfPages
        }
        changePage(newPage)
    }
    return (
        <Wrapper style={{display:'flex', justifyContent:'center', justifyItems:'center'}}> 
            <button className='prev-btn' onClick={prevPage}>
                <HiChevronDoubleLeft />
                prev
            </button>
            <div className='btn-container' style={{float:'left'}}>
                {pages.map((pageNumber) => {
                    return (
                        <button 
                        type='button'
                        className={pageNumber === page ? 'pageBtn active' : 'pageBtn'}
                        key={pageNumber}
                        onClick={() => changePage(pageNumber)}
                        style={{float:'left'}}
                        >
                            {pageNumber}
                        </button>
                    )
                })}
            </div>
            <button className='next-btn' onClick={nextPage}>
                next
                <HiChevronDoubleRight />
            </button>
        </Wrapper>
    )
};

export default PageBtnContainer;
