
import React from "react";
import '../styles/Produkter.css';
import { Col, Row, Container } from "react-bootstrap";
// import VerticalTabs from "../components/produkter/produktSektion";
import HeroVideo from "../components/produkter/produktVideo";
import Prisliste from "../components/produkter/prisListe";
// import ProductIntro from "../components/produkter/ProductIntro";
//import ProductFacts from "../components/produkter/ProductFacts";


function Products() {
    return (
        <div className="Products">   

            <Container fluid >  

                {/* SERVICE ABONNOMENT STARTS */}

                <div className='hero-section pb-4 mb-4'>
                    <Row style={{ marginLeft: 0, marginRight: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Col sm={10} md={10} lg={8} xl={8} style={{paddingLeft:0, paddingRight:0}}>
                            <h2 className="text-center py-2" style={{fontFamily:'Roboto', fontWeight:'bold', marginTop:'30px', marginBottom:'30px'}}>Help your students overcome learning barriers in STEM</h2>
                            <figure>
                                <HeroVideo/>
                            </figure>
                        </Col>
                    </Row>
                </div>

                <div className="ydelser-sektion">
                    <div className="ydelser-titel text-center">
                        <Row>
                            <Col>
                                <h1 style={{fontFamily:'Montserrat', fontWeight:'bolder'}}>Our products and services</h1>
                                <hr></hr>
                            </Col>
                        </Row>
                    </div>
                    <Prisliste/>
                </div>
                
                {/* SERVICE ABONNOMENT ENDS */}


    
                {/* PRODUCT INTRODUCTION STARTS */}

                {/* <ProductIntro /> */}
                
                {/* PRODUCT INTRODUCTION ENDS */}


                
                {/* PRODUCT FACTS STARTS */}
                {/*
                <Row className="mt-2 pt-2">
                    <Col>
                        <h3 className="text-center mt-4 pt-4" style={{fontFamily:'Montserrat'}}>Interesting facts for our products</h3>
                        <h5 className="text-center text-secondary py-2" style={{fontFamily:'Montserrat'}}>Our prodcuts are right below this info!</h5>
                    </Col>
                </Row>

                <ProductFacts />
                */}
                {/* PRODUCT FACTS ENDS */} 
                




                {/* PRODUCT SECTION STARTS */}

                {/* <div className="produkt-sektion" style={{marginBottom:50}}>
                    <Row style={{ marginLeft: 0, marginRight: 0 }}>
                        <Col style={{paddingLeft:0, paddingRight:0}}>
                            <div className="produkt-sektion-titel text-center">
                                <h1 style={{fontFamily:'Montserrat'}}>Our Products</h1>
                                <h5 className="text-secondary mb-3 pb-3" style={{fontFamily:'Montserrat'}}>Valuable information about out products!</h5>
                                <hr></hr>
                            </div>
                            <VerticalTabs/>
                        </Col>
                    </Row>
                </div> */}

                {/* PRODUCT SECTION STARTS */}


            </Container>

            

        </div>
    );
}


export default Products;