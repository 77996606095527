
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AnalyticEvent } from '../GA4/AnalyticEvent';
import '../../styles/About.css';


const socialTrackingEvent = (socialName) => {
    AnalyticEvent('Social link clicked' , socialName)
}

const AboutMedia = () => {

    return(
        <Container style={{marginTop: '30px'}}>
                <Row>
                    <Col>
                        <div className="about-button-container">
                            <div className="about-glass-btn blue-btn">
                                <img src={require('../../img/facebooklogo.jpg')} alt="facebook" style={{width:'96px', }} onClick={() => socialTrackingEvent('Facebook media')} />
                            </div>

                            <div className="about-glass-btn red-btn">
                                <img src={require('../../img/youtubelogo.png')} alt="youtube" style={{width:'96px', }} onClick={() => socialTrackingEvent('Youtube media')} />
                            </div>
                            
                            <div className="about-glass-btn amber-btn">
                                <img src={require('../../img/linkedin2.png')} alt="linkedin" style={{width:'96px',}} onClick={() => socialTrackingEvent('LinkedIn media')} />
                            </div>
                        </div>
                    </Col>
                </Row>
        </Container>
    )
}


export default AboutMedia;