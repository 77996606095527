
import React from "react";
import { Container } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsFillBellFill } from "react-icons/bs";
import { useAppContext } from '../../context/appContext';
import { FormRow, Alert } from '../../components';
import UnSub from "./UnSub";
import { AnalyticEvent } from '../GA4/AnalyticEvent.js';


export default function Subscriber() {

    const {isLoading, createSub, showAlert, displayAlert, subscribeEmail, handleChange, } = useAppContext();

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!subscribeEmail) {
            displayAlert()
            AnalyticEvent("subscribe failed")
            return
        }
        AnalyticEvent("subscribe Success")
        createSub();
    }

    const handleSubInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        handleChange({ name, value })
    }

    
    return (

        <Container>
            <React.Fragment>
                <div className="subscriber-body">
                    <div className="subscriber-title text-center text-light mb-4">
                        <h4 style={{fontFamily: 'Montserrat'}}>Stay informed about our latest updates. Subscribe to our newsletter today!</h4>
                        <hr></hr>
                    </div>
                    <div className="subscriber-form">
                        {showAlert && <Alert />}
                        <InputGroup className="mb-2" id="sub-input" style={{fontFamily:'Roboto', display:'flex', justifyContent:'center', alignItems:'center', fontSize:'14px', padding:'10px'}}>
                            {/* Email*/}
                            <div className="" style={{ maxWidth:'800px'}}>
                                <FormRow
                                    style={{borderTopLeftRadius:30, borderBottomLeftRadius:30}}
                                    className="text-dark"
                                    placeholder='example@example.com'
                                    type='text'
                                    name='subscribeEmail'
                                    value={subscribeEmail}
                                    handleChange={handleSubInput}
                                />
                            </div>
                            <Button className='home-inputgroup-button' type="submit" style={{backgroundColor:'#F28C28', borderTopRightRadius:30, borderBottomRightRadius:30, maxHeight:'40px'}} variant="outline-dark" onClick={handleSubmit}  disabled={isLoading} >
                                <BsFillBellFill size={20} style={{marginBottom:4, marginRight:5}} /> 
                                Subscribe
                            </Button>
                        </InputGroup>
                    </div>
                    <UnSub/>
                </div>
            </React.Fragment>
        </Container>

    )
}