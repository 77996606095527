
import React from 'react';
import ContactForm from '../components/Contact/ContactForm';

const Contact = () => {

    return (
        <div>
            <h2 className='text-center' style={{marginTop:30, paddingBottom:10}}>
                Get in contact with us
                <hr className='mx-auto' style={{maxWidth:'600px'}}/>
            </h2>
            <ContactForm />
        </div>
    );

};

export default Contact;
