
import { useEffect, useState } from 'react';
import { useAppContext } from '../context/appContext';
import Loading from './Loading';
import Alert from './Alert';
import PageBtnContainer from './PageBtnContainer';
import Sub from './Sub';
import axios from 'axios';
import { Container } from 'react-bootstrap';


const SubsContainer = (props) => {
    const { getSubs, totalSubs, isLoading, page, search, searchStatus, sort, numOfPages, showAlert, } = useAppContext();

    const [subss, setSubss] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [totalSubss, setTotalSubss] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [numOfPagess, setNumOfPagess] = useState(0);

    useEffect(() => {
        getSubs(props.isSubPage);
        getSubsApi(props.isSubPage);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, search, searchStatus, sort, props.isSubPage]);
    

    async function getSubsApi(isSubPage) {
        
        let urlsub = '';
        if (isSubPage) {
            // API frontend
            urlsub = `/sub?isLandingSub=${isSubPage}`; 

        }
        else {
            // API backend
            urlsub = `/sub-admin?isLandingSub=${isSubPage}page=${page}&status=${searchStatus}&sort=${sort}`; 

        }
        try {
            const authFetch = axios.create({
                baseURL: 'api/v1',
            });
            // Response interceptor
            authFetch.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    console.log(error.response);
                    if (error.response.status === 401) {
                    }
                    return Promise.reject(error);
                }
            );

            const { data } = await authFetch.get(urlsub);
            const { subs, totalSubs, numOfPages } = data;

            setSubss(subs);
            setTotalSubss(totalSubs);
            setNumOfPagess(numOfPages);
        } catch (error) {
            console.log(error.message);
        }
    }


    if (isLoading) {
        return <Loading center />;
    }

    if (!subss || subss.length === 0) {
        return (
            <Container>
                <h2>No subs to display...</h2>
            </Container>
        );
    }

    return (
        <Container>
            {showAlert && <Alert />}
            <hr></hr>
            <h5 className='my-4'>
                <span style={{color:'red'}}>{totalSubs}</span> Subs with status: {subss.length > 0 } "<span style={{color:'red'}}>{searchStatus}</span>"
            </h5>
            <div className='subs'>
                {subss.map((sub) => {
                    return <Sub key={sub._id} {...sub} isPage={props.isSubPage} />;
                })}
            </div>
            {numOfPages > 1 && <PageBtnContainer />}
        </Container>
    );
};

export default SubsContainer;