
import React from 'react'
import { FormRow , Alert } from '../../components'
import { useAppContext } from '../../context/appContext'
import Wrapper from '../../assets/wrappers/DashboardFormPage'
import WorkersContainer from '../../components/WorkersContainer'
import TextAreaRow from '../../components/TextAreaRow'


const Workers = () => {

    const { isLoading, isEditing, showAlert, displayAlert, workersname, workersposition, workerstext, blogImage, handleChange, handleInputFileChange, clearWorkerValues, createWorker, editWorker, } = useAppContext();

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!workersname || !workersposition || !workerstext || !blogImage) {
            displayAlert()
            return
        }
        if (isEditing) {
            editWorker()
            return
        }
        createWorker();

    }

    const handleWorkerInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        handleChange({ name, value })
    }

    const handleFileChange = (e) => {
        if (e.target.files) {
            const name = e.target.name
            const value = e.target.files[0]
            handleInputFileChange({ name, value })
        }
    };

    return (
        <Wrapper>
            <form className='form'>
                <h3>{isEditing ? 'edit worker' : 'add worker'}</h3>
                {showAlert && <Alert />}
                <div className='form-center'>
                    {/* Name */}
                    <FormRow
                        placeholder='Your name'
                        type='text'
                        name='workersname'
                        value={workersname}
                        handleChange={handleWorkerInput}
                    />
                    {/* Position */}
                    <FormRow
                        placeholder='Your position'
                        type='text'
                        name='workersposition'
                        value={workersposition}
                        handleChange={handleWorkerInput}
                    />
                    {/* Text */}
                    <TextAreaRow
                        placeholder='Your description'
                        type='text'
                        name='workerstext'
                        value={workerstext}
                        handleChange={handleWorkerInput}
                    />
                    {/* Image */}
                    <FormRow
                        type='file'
                        name='blogImage'
                        // value={blogImage}
                        handleChange={handleFileChange}
                    />
                    {/* btn container */}
                    <div className='btn-container'>
                        <button
                            type='submit'
                            className='btn btn-block submit-btn'
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            submit
                        </button>
                        <button
                            className='btn btn-block clear-btn'
                            onClick={(e) => {
                                e.preventDefault()
                                clearWorkerValues()
                            }}
                        >
                            clear
                        </button>
                    </div>
                </div>
            </form>

            {/* For backend display */}
            <WorkersContainer isWorkerPage={false} />
        </Wrapper>


    )
}

export default Workers;