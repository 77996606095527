

import * as React from 'react';
import { Card, Col, Image, Row } from "react-bootstrap";
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useAppContext } from '../context/appContext';

import '../styles/Home.css';



const Worker = ({ _id, workersname, workersposition, workerstext, blogImage, isWorkerPage }) => {

    const { setEditWorker, deleteWorker } = useAppContext()

    // Get the index of the last occurrence of 'blogImages/' in the URL
    const lastIndex = blogImage?.lastIndexOf('blogImages/');

    // Extract the image name from the URL using substring
    const blogImageName = blogImage?.substring(lastIndex + 11);

    return (
        <div className="scientist-component">
            <Row>
                <Col className="pb-3">
                    <Card className="card-custom bg-white border-white border-0 shadow-lg" style={{borderRadius:20, maxHeight:550, maxWidth:350}} border="info mx-2">
                        <div className="card-custom-img">
                            <Image src={require('../img/scientistbg.jpg')} alt="bg"/> 
                        </div>
                        <div className="card-custom-avatar">
                            <Image className="img-fluid" src={"../../blogImages/" + blogImageName} alt="Avatar"/>
                        </div>
                        <Card.Body style={{overflowY: 'auto'}}>
                            <Card.Title>
                                <h4>{workersname}</h4>
                                <h5>{workersposition}</h5>
                            </Card.Title>
                            <hr></hr>
                            <div style={{display:"flex", alignItems: "center", justifyContent:"end"}}>
                                < FaQuoteRight  style={{display:"flex", alignItems: "center", justifyContent:"end", margin: '0px', marginBottom:10}} size={20}/>
                            </div>
                            <Card.Text>
                                {workerstext}
                            </Card.Text>
                            < FaQuoteLeft size={20}/>
                        </Card.Body>
                        
                        <Card.Footer className="text-center" style={{background:'linear-gradient(305deg, rgba(44,106,148,1) 0%, rgba(14,159,255,1) 50%, rgba(60,159,225,1) 100%)', borderColor:'inherit', paddingTop:'10px', paddingBottom:'10px'}}>
                        </Card.Footer>
                    </Card>
                    {isWorkerPage ? (<>
                </>) : <>
                    <footer>
                        <div className='actions mx-4'>
                            <Link
                                to='/workers'
                                className='btn edit-btn btn-success'
                                onClick={() => setEditWorker(_id)}
                            >
                                Edit
                            </Link>
                            <button
                                type='button'
                                className='btn delete-btn btn-danger mx-2'
                                onClick={() => deleteWorker(_id)}
                            >
                                Delete
                            </button>
                        </div>
                    </footer>
                </>}
                </Col>
            </Row>
        </div>
    );
}

export default Worker;