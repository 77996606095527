
import React from 'react';
import { Ratio } from 'react-bootstrap';
import '../../styles/Produkter.css';


//controls width="100%"

// const src = "https://nanoscalesimulations.com/Gallery/BIG-MAP%20demo%201.1.mov";

const HeroVideo = () => {

    return (
        <div className='video-wrapper'>
            <Ratio aspectRatio="16x9" style={{maxHeight:'700px'}}>
                <iframe src="https://www.youtube.com/embed/mINwpCkegwo?"  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Ratio>
        </div>
    )

}

export default HeroVideo;