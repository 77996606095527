
const FormRow = ({ type, name, value, handleChange, labelText, placeholder }) => {

    return (
        <div className='form-row'>
            <input
            placeholder={placeholder}
            type={type}
            value={value}
            name={name}
            onChange={handleChange}
            className='form-input'
            />
        </div>
    )
};

export default FormRow;