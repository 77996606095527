
import React from 'react';
import './styles/CookieBanner.css';
import posthog from 'posthog-js';
import { useState } from 'react';
import { Button } from 'react-bootstrap';


const CookieBanner = () => {

    const [showBanner, setShowBanner] = useState(true); 

    const acceptCookies = () => { 
        posthog.opt_in_capturing();
        setShowBanner(false); 
    };

    const declineCookies = () => {
        posthog.opt_out_capturing();
        setShowBanner(false); 
    };

    

    return (
        <>
            {showBanner && ( 
                <div className='cookiebanner-body p-2 animate flip my-2 mx-auto rounded' style={{backgroundColor:'#828282', maxWidth:'768px', position: 'absolute', bottom: '-710px'}}>

                    <p className='one animate pop delay-1 fw-semibold fs-5 fst-arial text-white mx-4'>
                        We use cookies to understand how you use our website 
                        and improve it.<br/>
                        Please accept cookies to help us improve this site.
                    </p>

                    <div className='cookiebanner-btn mx-4' style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                        <div className='two animate slide delay-2'>
                            <Button className='pulse' type="button" variant='primary' onClick={acceptCookies}>
                                Accept Cookies
                            </Button>
                        </div>
                        <div className='three animate blur delay-3'>
                            <Button className='pulse mx-2' variant='danger' type="button" onClick={declineCookies}>
                                Decline Cookies
                            </Button>
                        </div>
                    </div>

                </div>
            )}
        </>
    );

};



export default CookieBanner;