import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import SignUp from '../CTA/SignUp';
import "../../styles/CTA.css";
import { AnalyticEvent } from '../GA4/AnalyticEvent';

const NavBarTrackingEvent = (menuName) => {
    AnalyticEvent('Navbar  linked tracked' , menuName)
}


const SubTrackingEvent = (subName) => {
    AnalyticEvent('Subscribe component clicked' , subName)
}

function CollapsibleExample () {
    return (
        <Navbar className='footer-section' collapseOnSelect sticky="top" expand="lg" bg="#233243" variant="dark" style={{zIndex:99}}>
            <Container fluid style={{paddingLeft:30, paddingRight: 30}}>
                <Navbar.Brand href="/landing"><img style={{height:'70px', paddingRight:'0px'}} src={require('../../img/logo.png')} alt="logo" onClick={() => NavBarTrackingEvent('Image Logo')}></img></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="nav-body mx-auto">
                        <Nav.Link href="/products"><h5 className='hover-4' onClick={() => NavBarTrackingEvent('Products page')}>Products</h5></Nav.Link>
                        {/* <Nav.Link href="/about"><h5 className='hover-4' onClick={() => NavBarTrackingEvent('About page')} >About</h5></Nav.Link> */}
                        <Nav.Link href="/contact"><h5 className='hover-4' onClick={() => NavBarTrackingEvent('Contact page')}>Contact</h5></Nav.Link>
                        {/* <Nav.Link href="/blog"><h5 className='hover-4' onClick={() => NavBarTrackingEvent('Blog page')}>Blog</h5></Nav.Link> */}
                    </Nav>
                    <Form className="d-flex" onClick={() => SubTrackingEvent('Subscribe component')}>
                        <SignUp/>
                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CollapsibleExample;