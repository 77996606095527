import * as React from 'react';
import '../styles/Blog.css';
// import moment from 'moment';
import { Link } from 'react-router-dom';
import { useAppContext } from '../context/appContext';
import { Container, Accordion } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa';


const Faq = ({ _id, faqtitle, faqtext, createdAt, isFaqPage }) => {

    const { setEditFaq, deleteFaq } = useAppContext()

    /*
    let date = moment(createdAt)
    date = date.format('MMM Do, YYYY');
    */

    return (

        <div style={{ paddingBottom: 30 }}>
            <Container>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <FaQuestionCircle size={24} color='#3C9FE1'/><div className='px-1'></div><span style={{fontWeight:'bold', fontFamily: 'Arial'}}>{faqtitle}</span>
                        </Accordion.Header>
                        <Accordion.Body>
                        {faqtext} <br></br>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                    { isFaqPage ? (<>
                    </>) : <>
                        <footer>
                            <div className='actions'>
                                <Link
                                    to='/faqadmin'
                                    className='btn edit-btn btn-success'
                                    onClick={() => setEditFaq(_id)}
                                >
                                    Edit
                                </Link>
                                <button
                                    type='button'
                                    className='btn delete-btn btn-danger mx-2'
                                    onClick={() => deleteFaq(_id)}
                                >
                                    Delete
                                </button>
                            </div>
                        </footer>
                    </>}
            </Container>
        </div>

    )
}

export default Faq;