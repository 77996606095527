
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../styles/About.css';

const Vision = () => {

    return (
        <Container style={{marginTop:15}} >
            <Row style={{ marginLeft:0, marginRight:0, }}>

                <Col sm={12} md={12} lg={4} xl={4} style={{ paddingLeft:0, paddingRight:0, }}>
                <div className=''>
                <img alt="vision" src={require('../../img/galleri4.jpg')}  />
                </div>
                    <div className='text-dark text-center pt-2'>
                        <p>Lærfest '22, Copenhagen Denmark</p>
                        <p className='text-secondary'>Dato: 16-03-2022</p>
                        <hr></hr>
                    </div>
                </Col>

                <Col sm={12} md={12} lg={8} xl={8} className='text-dark'>
                    <div className='vision-brødtekst'>
                        <h5 style={{color:'#3C9FE1', fontFamily:'Montserrat', fontWeight: 'bold'}}>Simulations for hands-on exploring of atoms and molecules</h5>
                        <p style={{fontFamily: 'Obito'}}>
                        Through simulations, students can manipulate atoms, experiment with different bonding scenarios, 
                        and observe real-time reactions, enhancing their understanding of chemistry in a visually captivating manner. 
                        It's an innovative and effective way to spark curiosity and empower learners to delve deeper into the molecular realm.
                        Thanks to modern technology, we are now able to make atoms tangible and thereby lower intuition and learning barriers.  
                        </p>
                        <br></br>
                        <h5 style={{color:'#3C9FE1', fontFamily:'Montserrat', fontWeight: 'bold'}}>Running atomic calculations should not be as hard as it is</h5>
                        <p style={{fontFamily: 'Obito'}}>
                        At Nanoscale Simulations, we believe that scientific research and exploration should be streamlined and accessible to all. 
                        Our mission is to simplify the complex, making atomic-scale computations a seamless and user-friendly experience for researchers, 
                        scientists, and enthusiasts alike.    
                        </p>
                    </div>
                </Col>

            </Row>
        </Container>
    )

};


export default Vision;