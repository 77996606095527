
import React from 'react';
import Wrapper from '../../assets/wrappers/DashboardFormPage';
import EmailsContainer from '../../components/EmailsContainer';
import { SearchContainerEmail } from '../../components';
import { FormRowSelect } from '../../components'
import { useAppContext } from '../../context/appContext'



const EmailAdmin = () => {

    const { isLoading, displayAlert, statusemail, handleChange, editEmail, statusOptionsEmail } = useAppContext();


    const handleSubmit = (e) => {
        e.preventDefault();

        if (!statusemail  ) {
            displayAlert()
            return
        }
        if (statusemail) {
            editEmail()
            return
        }
    }

    const handleEmailInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        handleChange({ name, value })
    }


    return (
        <Wrapper>

            <form className='form pb-4 mb-4'>
                <h3>Edit Email status</h3>
                <div className='form-center'>

                    {/* status email*/}
                    <FormRowSelect
                                name='statusemail'
                                value={statusemail}
                                handleChange={handleEmailInput}
                                list={statusOptionsEmail}
                            />
                    {/* btn container */}
                    <div className='btn-container'>
                        <button type='submit' className='btn btn-block submit-btn' onClick={handleSubmit} disabled={isLoading} >
                            submit
                        </button>
                    </div>
                </div>
            </form>

            <SearchContainerEmail />
            <hr className='pb-2 mb-2'></hr>
            <EmailsContainer />
            
        </Wrapper>

    );
};

export default EmailAdmin;