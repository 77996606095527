
import React from 'react'
import { FormRow , Alert } from '../../components'
import { useAppContext } from '../../context/appContext'
import Wrapper from '../../assets/wrappers/DashboardFormPage'
import BlogsContainer from '../../components/BlogsContainer'
import TextAreaRow from '../../components/TextAreaRow'


const Blogs = () => {

    const { isLoading, isEditing, showAlert, displayAlert, title, subtitle, author, description, fulltext, fulltexttwo, tagone, tagtwo, tagthree, tagfour,  blogImage, handleChange, handleInputFileChange, clearBlogValues, createBlog, editBlog, } = useAppContext();

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!title || !subtitle || !author || !description || !fulltext || !blogImage || !fulltexttwo || !tagone || !tagtwo || !tagthree || !tagfour) {
            displayAlert()
            return
        }
        if (isEditing) {
            editBlog()
            return
        }
        createBlog();

    }

    const handleJobInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        handleChange({ name, value })
    }

    const handleFileChange = (e) => {
        if (e.target.files) {
            const name = e.target.name
            const value = e.target.files[0]
            handleInputFileChange({ name, value })
        }
    };

    return (
        <Wrapper>
            <form className='form'>
                <h3>{isEditing ? 'edit blog' : 'add blog'}</h3>
                {showAlert && <Alert />}
                <div className='form-center'>
                    {/* title */}
                    <FormRow
                        placeholder='Title'
                        type='text'
                        name='title'
                        value={title}
                        handleChange={handleJobInput}
                    />
                    {/* subtitle */}
                    <FormRow
                        placeholder='Sub title'
                        type='text'
                        name='subtitle'
                        value={subtitle}
                        handleChange={handleJobInput}
                    />
                    {/* author */}
                    <FormRow
                        placeholder='Author'
                        type='text'
                        name='author'
                        value={author}
                        handleChange={handleJobInput}
                    />
                    {/* description */}
                    <FormRow
                        placeholder='Describtion'
                        type='text'
                        name='description'
                        value={description}
                        handleChange={handleJobInput}
                    />
                    {/* Tag One */}
                    <FormRow
                        placeholder='Tag one name'
                        type='text'
                        name='tagone'
                        value={tagone}
                        handleChange={handleJobInput}
                    />
                    {/* Tag Two */}
                    <FormRow
                        placeholder='Tag two name'
                        type='text'
                        name='tagtwo'
                        value={tagtwo}
                        handleChange={handleJobInput}
                    />
                    {/* Tag Three */}
                    <FormRow
                        placeholder='Tag three name'
                        type='text'
                        name='tagthree'
                        value={tagthree}
                        handleChange={handleJobInput}
                    />
                    {/* Tag Four */}
                    <FormRow
                        placeholder='Tag four name'
                        type='text'
                        name='tagfour'
                        value={tagfour}
                        handleChange={handleJobInput}
                    />
                    {/* image */}
                    <FormRow
                        type='file'
                        name='blogImage'
                        // value={blogImage}
                        handleChange={handleFileChange}
                    />
                    {/* fulltext */}
                    <TextAreaRow
                        placeholder='Full detail text'
                        type='text'
                        name='fulltext'
                        value={fulltext}
                        handleChange={handleJobInput}
                    />
                    {/* fulltext two */}
                    <TextAreaRow
                        placeholder='Full detail text two'
                        type='text'
                        name='fulltexttwo'
                        value={fulltexttwo}
                        handleChange={handleJobInput}
                    />
                    {/* btn container */}
                    <div className='btn-container'>
                        <button
                            type='submit'
                            className='btn btn-block submit-btn'
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            submit
                        </button>
                        <button
                            className='btn btn-block clear-btn'
                            onClick={(e) => {
                                e.preventDefault()
                                clearBlogValues()
                            }}
                        >
                            clear
                        </button>
                    </div>
                </div>
            </form>

            {/* For backend display */}
            <BlogsContainer isBlogPage={false} />
        </Wrapper>


    )
}

export default Blogs