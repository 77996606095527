
import { useEffect } from 'react';
import { useAppContext } from '../../context/appContext';
import { StatsContainer, Loading, ChartsContainer, StatsContainerEmail, ChartsContainerEmail } from '../../components';



const Stats = () => {

    const { showStats, isLoading, monthlyApplications, showStatsEmail, monthlyApplicationsEmail } = useAppContext();
    useEffect(() => {
        showStats();
        showStatsEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading center />;
    }

    return (
        <div className='' style={{marginTop: '30px', fontFamily: 'arial'}}>
            <h2 className='text-center py-2 my-2'>Subscribe stats section</h2>
            <StatsContainer />
            {monthlyApplications.length > 0 && <ChartsContainer />}
            <h2 className='text-center' style={{marginTop: '50px'}}>Email stats section</h2>
            <StatsContainerEmail />
            {monthlyApplicationsEmail.length > 0 && <ChartsContainerEmail />}
        </div>
    );

};


export default Stats;